@media(min-width: 320px) {
    .custom_container {
        padding-left: 12px;
    }

    .custom_container .slick-dots {
        left: 0%;
    }
}

@media(min-width: 576px) {
    .custom_container {
        margin-left: calc(50% - 258px);
    }

    .custom_container .slick-dots {
        left: calc(-50% + 258px);
    }
}

@media(min-width: 768px) {
    .custom_container {
        margin-left: calc(50% - 348px);
    }

    .custom_container .slick-dots {
        left: calc(-50% + 348px);
    }

}

@media(min-width: 992px) {
    .custom_container {
        margin-left: calc(50% - 468px);
    }

    .custom_container .slick-dots {
        left: calc(-50% + 468px);
    }
}

@media(min-width: 1200px) {
    .custom_container {
        margin-left: calc(50% - 558px);
    }

    .custom_container .slick-dots {
        left: calc(-50% + 558px);
    }
}

@media(min-width: 1400px) {
    .custom_container {
        margin-left: calc(50% - 688px);
    }

    .custom_container .slick-dots {
        left: calc(-50% + 688px);
    }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
    .custom_container {
        margin-left: calc(50% - 648px);
    }

    .custom_container .slick-dots {
        left: calc(-50% + 648px);
    }
}

@media(max-width: 1700px) {
    .good-taste h2 {
        font-size: 36px;
        line-height: 46px;
    }
}

@media(max-width: 1400px) {
    .restaurant h1 {
        width: 82%;
    }

    .dish h3 {
        line-height: 36px;
        font-size: 26px;
    }

    .single-blog .about-text h2 {
        font-size: 42px;
    }

    p {
        font-size: 16px;
        line-height: 26px;
    }

    .address span {
        margin-top: -5px;
        display: block;
    }

    .address h5 {
        font-size: 14px;
        line-height: 23px;
    }

    .address span {
        margin-bottom: 10px;
    }

    header {
        padding-top: 0;
    }

    .counter-container h3 {
        font-size: 34px;
        font-weight: bold;
    }

    .navbar {
        padding: 30px 0;
    }

    .counters-section h2 {
        font-size: 34px;
        line-height: 44px;
    }

    .counters-section .count-time h2 {
        font-size: 48px;
    }

    .news-section h2 {
        width: 50%;
    }

    .reviews-content h4 {
        font-size: 18px;
        line-height: 28px;
        padding-top: 24px;
    }

    .restaurant p {
        width: 89%;
    }

    .error-page form input[type="text"] {
        width: 60%;
    }

    .img-restaurant img,
    .about-text h2,
    .img-blog-full img,
    .photo-blog img {
        width: 100%;
    }

    .wilmington {
        width: 61%;
    }

    .best-restaurants .logos-card img {
        margin-right: 20px;
    }

    .join-img:before {
        width: 91%;
        height: 91%;
        top: 15%;
    }

    .news-post-two-data p {
        display: none;
    }

    .news-post-two h6 a {
        width: 100%;
    }

    .wilmington img {
        width: auto;
    }

    .wilmington.location-restaurant {
        top: 17%;
        width: 43%;
        left: 56%;
    }

    .food-content-section {
        padding-left: 30px;
    }

    .food-content-section h2 {
        font-weight: bold;
        width: 88%;
        font-size: 42px;
        line-height: 52px;
    }

    .error-page form button {
        right: 20%;
    }

    .about-img img {
        width: 90%;
        height: auto;
    }

    .about-img:before {
        height: 476px;
        width: 509px;
    }

    .our-mission-img img {
        width: 100%;
    }

    .our-mission-text h2 {
        font-size: 42px;
    }

    .card-text-data h3 {
        width: 100%;
    }

    .video-section-img:before {
        height: 46%;
        width: 82%;
    }

    .video-section-img i {
        left: 50%;
    }

    .video-section-img img {
        width: 100%;
    }

    .counter-hading h2 {
        font-size: 34px;
        line-height: 57px;
    }

    .about-counters-img img {
        width: 100%;
    }

    .our-team img {
        width: 100%;
    }

    .our-team:before {
        height: 324px;
        width: 362px;
    }

    .our-team p {
        width: 100%;
    }

    .subscribe-section.about .get-the-menu h2 {
        width: 100%;
    }

    .subscribe-section.about .get-the-menu input[type="text"] {
        width: 43%;
    }

    .subscribe-section.about .get-the-menu form i {
        left: 23%;
    }

    .benefit-text h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .quickeat {
        top: 8%;
        left: 8%;
    }

    ul.data li {
        padding-right: 13px;
    }

    .join-img-blog img {
        width: 100%;
    }

    .join-img-blog:before {
        width: 100%;
        height: 100%;
    }

    .counter-blog .counter-img-data {
        width: 45%;
        margin-left: 0;
        margin-right: 20px;
    }

    .news-posts-one.blog .quickeat {
        top: 7%;
        left: 11%;
    }

    .restaurants-girl-img.blog.food-photo-section a.one {
        top: 31%;
    }

    .restaurants-girl-img.blog.food-photo-section a.three {
        top: 59%;
    }

    .news-posts-one h3 {
        font-size: 30px;
        line-height: 40px;
    }

    .news-posts-one h3 {
        padding: 25px 0 25px;
    }

    .news-post-two {
        padding-bottom: 15px;
    }

    .restaurants-girl-img.blog.food-photo-section a.three {
        top: 61%;
    }

    .restaurants-girl-img.blog.food-photo-section a.one {
        top: 29%;
    }

    .contact-us-img img,
    .form-section-img img {
        width: 100%;
    }

    .address h6 {
        font-size: 13px;
    }

    .address span {
        font-size: 10px;
    }

    .hero-section .join-img-blog img {
        margin-top: 40px;
    }

    .join-img-blog i {
        top: 17%;
    }

    .pricing-table-cards img {
        left: -47%;
    }

    .pricing-table-cards-pric h4 {
        font-size: 60px;
    }

    .pricing-table-cards-pric {
        margin-left: 119px;
    }

    .pricing-table-cards h6 {
        font-size: 16px;
        z-index: 11;
        padding: 7px 16px;
    }

    .pricing-plan-list h5 {
        font-size: 14px;
        margin: 0;
    }

    .dish img {
        padding-bottom: 20px;
        width: 100%;
    }

    .footer-description h2 {
        width: 100%;
    }

    .footer-description p {
        width: 85%;
    }

    .restaurants-girl-img.food-photo-section a.one {
        top: 14%;
    }

    .restaurants-girl-img.food-photo-section a.three {
        top: 53%;
    }

    .restaurants-girl-img.food-photo-section a.for {
        top: 72%;
    }

    .logos-card.restaurant-page {
        flex-wrap: wrap;
        justify-content: start;
    }

    .cafa {
        margin-left: 0;
    }

    .logos-card img {
        margin-bottom: 20px;
    }

    .counter-img img {
        width: 100%;
    }

    .counter-img-data {
        width: 70%;
    }

    .hero-section-img-service:before {
        height: 517px;
        width: 484px;
    }

    .services-card-style {
        padding: 24px;
    }

    .work-card.service img {
        width: 100%;
    }

    .sit-at-img img {
        width: 100%;
    }

    .sit-at-img:before {
        height: 92%;
        width: 100%;
    }

    .sit-at-img .counter-img-data {
        bottom: 18%;
        width: 36%;
        right: -11%;
    }

    .menu-inner .counter-container {
        padding: 0;
    }

    .menu-inner .checkout-order .banner-wilmington {
        margin-left: 0;
        padding-top: 0px;
    }

    .menu-inner .totel-price h2 {
        font-weight: bold;
        line-height: 44px;
        font-size: 32px;
    }

    body.active .menu-inner {
        padding-top: 10px;
    }

    .menu-inner .totel-price h2 {
        font-weight: bold;
        line-height: 48px;
        font-size: 38px;
    }

    .menu-inner .counter-container h4 {
        font-size: 16px;
        margin: 0;
        line-height: 26px;
        width: 56%;
    }

    .menu-inner .price h2 {
        font-weight: bold;
        line-height: 48px;
        font-size: 38px;
    }

    .menu-inner .title-checkout h6,
    .menu-inner .title-checkout h2 {
        font-weight: bold;
        font-size: 30px;
    }

    .menu-inner .counter-food img {
        width: 100px;
    }

    .comment-slide.owl-carousel .owl-nav.disabled {
        left: -28%;
        top: 74%;
    }

    .good-taste h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .news-posts-one.blog h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .previous-posts h6 {
        font-size: 14px;
        line-height: 18px;
    }

    .team-description h4 {
        font-size: 20px;
        margin: 0;
    }
}

@media(max-width: 1200px) {
    .about-text h2 {
        width: 100%;
    }

    .previous-posts h6 {
        font-size: 18px;
        line-height: 28px;
    }

    .owl-dot {
        display: none !important;
    }

    .comment-slide.owl-carousel .owl-nav.disabled {
        left: 46%;
        top: 98%;
    }

    .hero-section {
        padding-top: 105px;
    }

    .good-taste {
        width: 100%;
        margin: auto;
        margin-left: 33px;
        margin-bottom: 40px;
    }

    .work-card.service {
        padding: 22px;
    }

    header.two {
        padding-top: 30px;
    }

    .card-text-data {
        margin-top: 40px;
    }

    .count-time {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .our-team:before {
        height: 64%;
        width: 100%;
    }

    .our-team-section .hading p {
        padding-bottom: 0px;
        width: 100%;
    }

    .our-team {
        margin-top: 40px;
    }

    .subscribe-section.about .get-the-menu input[type="text"] {
        width: 48%;
    }

    .subscribe-section.about .get-the-menu form i {
        left: 20%;
    }

    ul.data li {
        padding-right: 20px;
    }

    .join-the {
        margin-left: 20px;
    }

    .join-the h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .counter-blog .counter-img-data {
        width: 46%;
        padding: 20px;
    }

    .posts-section-blog .news-posts-one.full {
        flex-wrap: wrap;
    }

    .blog-post-data-img img {
        width: 873px;
    }

    .blog-post-data-img .quickeat {
        top: 6%;
        left: 6%;
    }

    .get-the-menu h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .address h6 {
        font-size: 16px;
    }

    .address span {
        font-size: 14px;
    }

    .contact-us-img img {
        margin-top: 40px;
    }

    .contact-map-data {
        flex-wrap: wrap;
    }

    .contact-map-data iframe {
        width: 100%;
        height: 350px;
    }

    .contact-map {
        width: 100%;
    }

    .Provides {
        flex-wrap: wrap;
        justify-content: center;
    }

    .Provides form {
        margin-top: 40px;
    }

    .questions,
    .questions.answer {
        width: 85%;
    }

    .pricing-table-cards img {
        left: -33%;
    }

    .pricing-table-cards-pric {
        margin-left: 159px;
    }

    .pricing-table-cards {
        margin-bottom: 40px;
    }

    .counter-img-data {
        width: 87%;
    }

    .hero-section-img-service:before {
        height: 454px;
        width: 388px;
    }

    .services-info h2,
    .services-cards-text h2 {
        width: 100%;
    }

    .services-info {
        margin-bottom: 40px;
    }

    .services-card-style {
        padding: 45px;
    }

    .services-cards-text h2 {
        margin-top: 40px;
    }

    .sit-at-home-description h2 {
        margin-top: 50px;
    }

    .sit-at-img .counter-img-data {
        width: 23%;
    }

    .restaurant h1 {
        width: 98%;
    }

    .wilmington {
        width: 67%;
    }

    .wilmington.location-restaurant {
        width: 53%;
    }

    .logos-card {
        flex-wrap: wrap;
    }

    .counters-section .count-time h2 {
        font-size: 70px;
    }

    .news-posts-one {
        margin-bottom: 40px;
    }

    .services-card-style {
        margin-bottom: 40px;
    }

    form.checkout-form {
        margin-top: 60px;
    }

    .navbar,
    .extras.bag {
        display: none;
    }

    header {
        padding: 30px 0;
    }

    .extras.bag .bar-menu {
        display: flex;
    }

    .mobile-nav {
        width: 45%;
    }

    .menu-inner {
        width: 52%;
    }

    .search_filter_box .filter_left {
        width: 50%;
    }

    .search_filter_box .filter_right {
        width: calc(50% - 14px);
    }

    .cart_page_wrapper .empty_cart_design {
        margin-top: 50px !important;
    }

    .cart_page_wrapper .empty_cart_design .concept_image img {
        max-width: 70% !important;
    }

    .img_content_resp img {
        max-width: 340px;
    }
}

@media(max-width: 992px) {
    .error-page p {
        width: 90%;
    }

    .services-cards-text h2 {
        margin-bottom: 0;
    }

    .services-cards-text {
        margin-bottom: 40PX;
    }

    .our-mission-section {
        padding: 0;
    }

    .food-content-section {
        padding-left: 0px;
    }

    .error-page h2 {
        right: 18%;
    }

    .author-text.item {
        margin-left: 2%;
        width: 98%;
    }

    .menu-inner {
        width: 70%;
    }

    .about-img img {
        margin-top: 40px;
    }

    .about-img:before {
        height: 645px;
        width: 627px;
    }

    .our-mission-img img {
        width: 100%;
        margin-bottom: 40px;
    }

    .wait-a-minute h2,
    .wait-a-minute p {
        width: 100%;
    }

    .video-section-img img {
        margin-top: 50px;
    }

    .video-section-img:before {
        height: 46%;
        width: 82%;
        top: 10%;
    }

    .about-counters-img img {
        margin-bottom: 40px;
    }

    .our-team:before {
        height: 303px;
    }

    .subscribe-section.about .get-the-menu h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .subscribe-section.about .get-the-menu form i {
        left: 17%;
    }

    .join-courier {
        margin-top: 40px;
        position: relative;
        top: 0%;
        width: 100%;
    }

    .Benefit-section {
        padding: 0;
    }

    .hero-section.about.blog-page {
        padding-bottom: 60px;
    }

    .team-description {
        padding: 24px;

    }

    .team-description h4 {
        font-size: 22px;
    }

    ul.data li {
        padding-right: 8px;
    }

    .counter-blog .counter-img-data {
        width: 48%;
        padding: 40px;
    }

    .join-the {
        margin-left: 0;
        margin-top: 80px;
    }

    .blog-post-data-img img,
    .reviews-content h2,
    .reviews-content h4 {
        width: 100%;
    }

    .food-photo-section img {
        width: 100%;
        margin-top: 40px;
    }

    .get-the-menu input[type="text"] {
        width: 80%;
    }

    .get-the-menu {
        margin-top: 40px;
    }

    .questions,
    .questions.answer {
        width: 95%;
    }

    .pricing-table-cards {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
    }

    .pricing-table-cards img {
        left: -19%;
    }

    .pricing-table-cards-pric h3 {
        width: 40%;
    }

    .hero-section.about .restaurant .nice-select {
        width: 100%;
    }

    .hero-section-img-service {
        margin-top: 65px;
    }

    .hero-section-img-service:before {
        height: 100%;
        width: 78%;
    }

    .counter-img-data {
        width: 61%;
    }

    .services-info p {
        width: 100%;
    }

    .work-card.service {
        padding: 70px;
    }

    .hading p {
        width: 100%;
    }

    .sit-at-img .counter-img-data {
        width: 30%;
    }

    .title-container h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .title-container h3 {
        font-size: 32px;
        line-height: 42px;
    }

    .previous-posts.next {
        justify-content: end;
    }

    .blog-content .name-dedails {
        padding: 20px 0;
    }

    .img-restaurant img {
        padding-top: 40px;
    }

    .count-time {
        margin-top: 40px;
    }

    .wilmington {
        width: 43%;

    }

    .wilmington img {
        padding-top: 0;
    }

    .reviews-img img {
        margin-top: 80px;
    }

    .food-content-section h2,
    .food-content-section p {
        width: 100%;
    }

    .reviews-img i {
        top: 17%;
    }

    .join-img:before {
        height: 86%;
    }

    .wilmington.location-restaurant {
        width: 34%;
    }

    .food-content-section h2 {
        margin-top: 20px;
    }

    .join-partnership h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .city-restaurants {
        margin-bottom: 40px;
    }

    .menu.contacts {
        margin-top: 40px;
    }

    .footer-two {
        flex-wrap: wrap;
        justify-content: center;
    }

    .page-ath-content {
        width: 50% !important;
    }

    .page-ath-gfx {
        width: 50% !important;
    }

    .cart_page_wrapper .empty_cart_design {
        margin-top: 0px !important;
    }

    .landing_section .landing_section_main h1,
    .how_its_work .how_its_work_main h5 {
        max-width: 80%;
    }

    .img_content_resp img {
        max-width: 320px;
    }

    .how_its_work .steps_content_wrapper .text_content h2,
    .download_app .text_content h2 {
        max-width: 80%;
        margin: auto;
        text-align: center;
    }

    .how_its_work .steps_content_wrapper .text_content p {
        text-align: center;
    }

    .how_its_work .steps_content_wrapper .text_content .download_btn_container {
        justify-content: center;
    }

    .faq_section .faq_content_wrapper {
        border-radius: 24px;
    }

    .featured_event .featured_event_content {
        height: auto;
        max-height: unset;
        min-height: unset;
    }

    .featured_event .featured_event_content .right_side {
        flex-direction: row;
    }

    .featured_event .featured_event_content .featured_event_card {
        height: unset;
        min-height: 250px;
    }

    .download_app .text_content {
        text-align: center;
    }
}

@media(max-width: 768px) {

    .error-page form input[type="text"],
    .mobile-nav,
    .menu-inner {
        width: 300px;
        padding: 30px 16px;
    }

    .our-team-section {
        padding-top: 0;
    }

    .error-page form button {
        right: 0%;
    }

    .about-text.pricing-table p {
        width: 100%;
    }

    .counter-container h4 {
        width: 57%;
    }

    .error-page h2 {
        right: 9%;
    }

    .error-page p {
        width: 100%;
    }

    .about-img:before {
        height: 490px;
        width: 495px;
    }

    .about-text p,
    .footer-description p {
        width: 100%;
    }

    .our-team:before {
        height: 466px;
        width: 100%;
    }

    .menu {
        margin-top: 40px;
    }

    .footer .menu {
        margin-top: 0px !important;
    }

    .footer-two {
        margin-top: 40px;
    }

    .subscribe-section.about .get-the-menu input[type="text"] {
        width: 66%;
    }

    .subscribe-section.about .get-the-menu h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .subscribe-section.about .get-the-menu form i {
        left: 4%;
    }

    .news-posts-one {
        margin-bottom: 40px;
    }

    .news-about-us .button-gap {
        padding-top: 0;
    }

    .questions,
    .questions.answer {
        width: 100%;
    }

    .questions {
        flex-wrap: wrap;
        align-items: start;
        justify-content: start;
    }

    .card-text-data h3 {
        font-size: 32px;
        line-height: 42px;
    }

    .price-list .price {
        padding-top: 7px;
    }

    .get-the-menu form i {
        left: 4%;
    }

    .questions h6 {
        padding-top: 15px;
    }

    .questions.answer {
        flex-wrap: inherit;
    }

    .questions.answer i {
        margin-top: -20px;
    }

    .pricing-table-cards img {
        left: -26%;
    }

    .dish {
        text-align: center;
    }

    .lunch-section h2 {
        font-weight: bold;
        width: 100%;
    }

    .like-meal {
        display: none;
    }

    .counter-img-data {
        width: 80%;
    }

    .services-cards-text ul.paragraph li {
        width: 100%;
    }

    .services-cards {
        padding: 0;
    }

    .sit-at-img .counter-img-data {
        width: 38%;
    }

    .sit-at-home-description h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .quote-text {
        flex-wrap: wrap;
    }

    .quote-text h4 {
        padding-left: 0;
        padding-top: 25px;
    }

    .blog-list li {
        width: 100%;
    }

    .quote {
        padding: 40px 0;
    }

    .photo-blog {
        padding: 20px 0;
    }

    .img-blog-full img {
        border-radius: 25px;
    }

    .hero-section.about.single-blog {
        padding-bottom: 40px;
    }

    ul.comment {
        padding: 0px 0 40px;
    }

    .single-blog .about-text h2 {
        font-size: 50px;
        line-height: 60px;
    }

    .food-photo-section a.one {
        top: 75%;
        right: 68%;
    }

    .food-photo-section a.two {
        top: 75%;
        right: 41%;
    }

    .food-photo-section a.three {
        top: 75%;
        right: 17%;
    }

    .nice-select-one {
        flex-wrap: wrap;
    }

    .restaurant .nice-select {
        width: 100%;
        margin-bottom: 20px;
    }

    .wilmington {
        width: 63%;
    }

    .wilmington.location-restaurant {
        width: 50%;
    }

    .city-restaurants h2,
    .news-section h2 {
        width: 100%;
    }

    .news-post-two {
        flex-wrap: wrap;
    }

    .news-post-two-data {
        margin-left: 0;
        margin-top: 40px;
    }

    .join-img:before {
        height: 91%;
        top: 13%;
    }

    .news-post-two h6 a {
        width: 100%;
    }

    .page-ath-gfx {
        display: none !important;
    }

    .page-ath-content {
        width: 100% !important;
    }

    .footer .footer-lower {
        flex-direction: column;
    }

    .landing_section .landing_section_main h1,
    .how_its_work .how_its_work_main h5,
    .how_its_work .steps_content_wrapper .text_content h2,
    .download_app .text_content h2 {
        max-width: 100%;
    }

    .img_content_resp img {
        max-width: 320px;
    }

    .partner_with_us .text_content {
        text-align: center;
    }

    .partner_with_us .text_content .button {
        min-width: 100%;
    }

    .landing_section {
        background-position: 100%;
    }

    .custom_steps .ant-steps .ant-steps-item .ant-steps-item-content {
        display: none;
    }

    .custom_steps .ant-steps .ant-steps-item .ant-steps-item-icon {
        margin-inline-start: 8px !important;
    }

    .custom_steps .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
        margin-inline-start: 56px !important;
        padding: 4px 16px !important;
    }

    .faq_section .faq_content_wrapper {
        border-radius: 18px;
        padding: 40px;
    }

    .faq_section .faq_content_wrapper>h3.fs28 {
        font-size: 24px !important;
    }

    .featured_event .featured_event_content .right_side {
        flex-direction: column;
    }

    .featured_event .featured_event_content .featured_event_card .event_cost {
        padding: 6px 18px;
        font-size: 18px;
    }

    .featured_event .featured_event_content .featured_event_card .event_cost span {
        font-size: 14px;
    }

    .landing_section_main .order_ctg_filter_wrapper .ctg_item .ctg_icon {
        width: 100px;
        height: 100px;
    }
}

@media(max-width: 576px) {
    .error-page img {
        width: 100%;
    }

    .thomas h6 {
        margin-top: 14px;
    }

    .author-text.item {
        margin-left: 0;
    }

    .about-text.pricing-table ul.crumbs {
        flex-wrap: wrap;
    }

    .work-card.service {
        padding: 40px;
    }

    .about-img img {
        width: 80%;
    }

    .benefit-text h2 {
        font-size: 34px;
        line-height: 49px;
    }

    .counter-hading h2 {
        font-size: 34px;
        line-height: 44px;
    }

    .about-img:before {
        display: none;
    }

    .count-time {
        margin-bottom: 20px;
    }

    .our-team:before {
        height: 70%;
    }

    .counter-blog {
        flex-wrap: wrap;
    }

    .counter-blog .counter-img-data {
        width: 100%;
        margin-bottom: 20px;
    }

    .join-img-blog:before {
        left: 0%;
    }

    .join-img-blog img {
        padding-right: 20px;
    }

    .join-img-blog:before {
        top: 13%;
    }

    .join-img-blog i {
        top: 9%;
        left: 0%;
    }

    .join-img-blog i {
        width: 80px;
        height: 80px;
        font-size: 40px;
    }

    .counter-img-data {
        padding: 20px;
        width: 68%;
    }

    .counter-img-data h2 {
        font-size: 50px;
    }

    .sit-at-img img {
        padding-right: 22px;
    }

    .sit-at-img .counter-img-data {
        right: 0;
    }

    .sit-at-img:before {
        width: 89%;
    }

    .count-time {
        margin-top: 20px;
    }

    .reviews-img img {
        margin-top: 120px;
    }

    .reviews-img i {
        top: 26%;
        font-size: 50px;
    }

    .wilmington {
        width: 70%;
    }

    .hading p {
        padding-top: 20px;
    }

    .mobile_action .button {
        padding: 8px 8px;
        height: auto;
        line-height: 1;
    }

    .mobile_action .button i {
        padding: 0px !important;
        margin: 0px !important;
    }

    .bag .button {
        font-size: 14px;
        padding: 0 14px;
        height: 38px;
        line-height: 34px;
    }

    .page-ath-form {
        max-width: 100% !important;
        padding: 50px 24px !important;
    }

    .event_details_hero_section .text_content_bottom {
        flex-direction: column;
        gap: 24px;
    }

    .extras.bag .button {
        display: none;
    }

    .landing_section .landing_section_main h1.fs48 {
        font-size: 32px !important;
    }

    .img_content_resp img {
        max-width: 280px;
    }

    .landing_section {
        min-height: 420px;
    }

    .how_its_work .steps_content_wrapper .text_content h2 {
        font-size: 26px !important;
    }

    .custom_steps .ant-steps .ant-steps-item .ant-steps-item-icon {
        width: 48px;
        height: 48px;
    }

    .custom_steps .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
        margin-inline-start: 38px !important;
    }

    .custom_steps .ant-steps .ant-steps-item-tail {
        top: 20px;
    }

    .faq_section .faq_content_wrapper {
        border-radius: 12px;
        padding: 30px;
    }

}

@media(max-width: 480px) {
    .wait-a-minute h2 {
        font-size: 38px;
        line-height: 48px;
    }

    .join-img:before {
        height: 87%;
        top: 18%;
    }

    .restaurant h1 {
        font-size: 42px;
        line-height: 50px;
    }

    .Join-courier h3 {
        font-size: 24px;
    }

    .Join-courier {
        bottom: 10%;
    }

    .title-container h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .title-container h3 {
        font-size: 24px;
        line-height: 34px;
    }

    .title-container h4 {
        font-size: 20px;
        line-height: 30px;
    }

    .our-team:before {
        height: 68%;
    }

    .subscribe-section.about .get-the-menu form {
        flex-wrap: wrap;
    }

    .subscribe-section.about .get-the-menu input[type="text"] {
        height: 60px;
        width: 100%;
        margin-bottom: 20px;
    }

    .subscribe-section.about .get-the-menu form i {
        top: 20%;
    }

    .blog-post-data-img .quickeat {
        top: 6%;
        left: 9%;
    }

    .news-posts-one h3 {
        padding: 23px 0 5px;
        font-size: 24px;
        line-height: 34px;
    }

    .restaurants-girl-img.blog.food-photo-section a {
        height: 50px;
        width: 50px;
    }

    .restaurants-girl-img.blog.food-photo-section a i {
        padding: 5px;
        font-size: 18px;
    }

    .get-the-menu h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .get-the-menu form {
        flex-wrap: wrap;
    }

    .get-the-menu input[type="text"] {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
    }

    .get-the-menu form i {
        top: 17%;
        left: 3%;
    }

    .nav-pills .nav-link {
        padding: 0 21px 0 7px;
    }

    .Provides input[type="text"] {
        width: 168px;
    }

    .pricing-table-cards img {
        left: -31%;
    }

    .pricing-table-cards-pric h3 {
        width: 100%;
    }

    .hours.two {
        top: 71%;
        left: 0;
    }

    .hours {
        top: 6%;
        left: 32%;
    }

    ul.crumbs li {
        padding-right: 10px;
    }

    .cafa-button a.end,
    .restaurants-girl-img.food-photo-section a.for {
        display: none;
    }

    .restaurants-girl-img.food-photo-section a.three {
        top: 55%;
    }

    .food-photo-section a.two {
        right: 16%;
        top: 61%;
    }

    .food-photo-section a.three {
        right: 57%;
        top: 79%;
    }

    .food-photo-section a {
        width: 40%;
    }

    .food-photo-section a.one {
        right: 59%;
        top: 61%;
    }

    .your-favorite-food .food-photo-section img {
        margin-top: 0;
        margin-bottom: 64px;
    }

    .wilmington.location-restaurant {
        width: 80%;
        left: 17%;
        top: 3%;
    }

    .img-restaurant .wilmington img {
        padding-top: 0;
    }

    .img-restaurant img {
        padding-top: 100px;
    }

    .wilmington {
        width: 89%;
    }

    .counter-container h4 {
        padding-left: 18px;
        font-size: 20px;
        line-height: 30px;
    }

    .get-the-menu form i {
        height: unset !important;
    }

    .img_content_resp img {
        max-width: 260px;
    }

    .order_ctg_filter_wrapper a .ctg_icon {
        width: 84px !important;
        height: 84px !important;
    }
    .order_ctg_filter_wrapper a .ctg_icon img,
    .order_ctg_filter_wrapper a .ctg_icon svg{
        width: 36px;
        height: 36px;
    }
}

@media(max-width: 450px) {
    .our-team:before {
        height: 62%;
    }

    .news-posts-one .quickeat a {
        font-size: 12px;
    }

    .Provides input[type="text"] {
        width: 130px;
    }

    .dish h3 {
        font-size: 28px;
        line-height: 38px;
    }

    .counter-img-data {
        width: 86%;
    }
}

@media(max-width: 410px) {
    .error-page h2 {
        right: 9%;
        font-size: 60px;
        top: 52%;
        padding: 10px;
    }

    .work-card {
        padding-top: 60px;
    }

    .Join-courier a.button.button-2 {
        font-size: 12px;
    }

    .Join-courier h3 {
        font-size: 24px;
        padding-bottom: 0;
        padding-top: 20px;
    }

    .count-time span {
        font-size: 60px;
    }

    .custome.owl-theme .owl-nav {
        bottom: -22%;
    }

    .counter-img-data h2 {
        padding-bottom: 0;
        font-size: 40px;
    }

    .pricing-table-cards img {
        left: -47%;
    }

    .pricing-table-cards-pric {
        margin-left: 115px;
    }

    .join-img-blog:before {
        top: 17%;
    }

    .Provides input[type="text"] {
        width: 92px;
    }

    .our-mission-text h2 {
        font-size: 33px;
        line-height: 43px;
    }

    .about-img:before {
        height: 327px;
        width: 310px;
    }

    .about-img:before {
        display: block;
    }

    .about-text h2 {
        font-size: 42px;
    }

    .join-the {
        margin-top: 46px;
    }

    .join-the h2 {
        font-size: 36px;
        line-height: 46px;
    }

    .nav-pills .nav-link {
        font-size: 16px;
        padding: 0 11px 0 0px;
    }
}

@media(max-width: 375px) {
    .about-img:before {
        height: 306px;
        width: 316px;
    }

    .pricing-table-cards img {
        left: -52%;
    }

    .pricing-table-cards-pric {
        margin-left: 115px;
    }

    .our-team:before {
        height: 59%;
    }
    .order_ctg_filter_wrapper a .ctg_icon {
        width: 74px !important;
        height:74px !important;
    }
    .order_ctg_filter_wrapper a .ctg_icon img{
         width: 28px !important;
         height: 28px !important;
    }
}

@media(max-width: 1599px) {

    .product_list_main .product_list_item .product_text h4,
    .product_list_main .product_list_item .product_text .price,
    .product_list_main .product_list_item .product_text .food_type {
        margin-bottom: 10px;
    }

    .rating_star_part img {
        width: 16px;
    }

    .shop_detials_banner.gap {
        padding: 60px 0px;
    }

    .shop_detials_banner .shop_text h1.fs40 {
        font-size: 30px !important;
    }

    .shop_detials_banner .shop_text h6.fs24 {
        font-size: 18px !important;
    }

    .order_header_wrapper .action_right_part ul.extras li.search-location-toogle-icon {
        display: block;
    }

    /* .header_flex_main .menu_part .search_filter_box .filter_left{
         width: 50% !important;
    } */
}

@media(max-width: 1399px) {
    header {
        padding: 10px 0;
    }

    .order_ctg_filter_slider a .ctg_icon {
        width: 100px;
        height: 100px;
        border-radius: 100px;
    }

    .order_ctg_filter_slider a {
        gap: 14px;
    }

    .rating_star_part img {
        width: 20px;
    }

    header .login_user_info .user_image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .products_grids_main .products_cards {
        padding: 14px;
    }

    .products_grids_main .product_action .wishlist_icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .header_flex_main .menu_part_wrapper .restaurants_search_filter {
        top: 70px !important;
    }
}

@media(max-width: 1199px) {
    .gap {
        padding: 60px 0;
    }

    .section_title {
        margin-bottom: 30px;
    }

    .products_card_text h5 a {
        font-size: 18px;
    }

    .products_card_text p {
        font-size: 14px;
        line-height: 1;
    }

    .products_card_text h5,
    .products_card_text p:not(:last-child) {
        margin-bottom: 8px;
    }

    .products_card_text .rating_badge {
        padding: 6px 8px;
    }

    .products_card_text .rating_badge img {
        width: 12px;
        line-height: 1;
    }

    .products_card_text .rating_badge p {
        font-size: 12px;
        line-height: 12px;
    }

    .offers_slider_main .offers_card {
        padding: 24px;
    }

    .slide_gap30 .slick-slide {
        padding-left: 12px;
        padding-right: 12px;
    }

    .slide_gap30 .slick-track {
        margin-left: -12px;
        margin-right: -12px;
    }

    .offers_slider_main .offers_card .text_part h3 {
        font-size: 26px;
        line-height: 1.3;
        margin-bottom: 8px !important;
    }

    .offers_slider_main .offers_card .text_part p {
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .shops_details_section .menu_items_card .menu_items_list {
        max-height: 600px;
    }

    .qty_button {
        gap: 8px;
        max-width: 120px;
        padding: 9px 14px;
    }

    .product_list_main .product_list_item.gap-4 {
        gap: 14px !important;
    }

    .product_list_main .product_list_item .product_image,
    .order_history_page .product_list_main .product_list_item .product_image {
        width: 100px;
        height: 100px;
        border-radius: 10px;
    }

    .product_list_main .product_list_item .product_text .price del {
        font-size: 16px;
        line-height: 1;
    }

    .product_list_main .product_list_item .product_text .price ins {
        font-size: 24px;
        line-height: 1;
    }

    .order_history_page .product_list_main .product_list_item .product_text h4,
    .order_history_page .product_list_main .product_list_item .product_text .mb20 {
        margin-bottom: 4px !important;
    }

    .order_history_page .product_list_main .product_list_item .product_text .fs20 {
        font-size: 16px !important;
        line-height: 1;
    }

    .order_history_page .product_list_main .product_list_item .product_text .order_time {
        font-size: 14px !important;
    }

    .order_history_page .product_list_main .product_list_item .product_text h4 a {
        font-size: 18px;
        line-height: 1.3;
    }

    .order_history_page .product_list_main li {
        padding-bottom: 14px;
        padding-top: 14px;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .main_text p,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .main_text p {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 4px;
        font-size: 14px;
        line-height: 1.3;
        align-items: flex-start;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .event_price_numaric,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .event_price_numaric {
        padding: 6px 8px;
        border-radius: 5px;
        margin-top: 10px;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .event_price_numaric span,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .event_price_numaric span {
        line-height: 1;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card,
    .popular_events .events_details_cards_wrapper .individual_card {
        padding: 14px;
        border-radius: 10px;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .main_text p span,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .main_text p span {
        font-size: 14px;
        line-height: 1;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .main_text h4,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .main_text h4 {
        font-size: 18px;
    }

    .header_flex_main .menu_part_wrapper .location_search_filter {
        position: absolute;
        top: 70px;
        left: 0px;
        width: 100%;
        padding: 10px;
        background: #ffffff !important;
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: none;
        border: 1px solid #d9d9d9;
    }

    .header_flex_main .menu_part_wrapper .location_search_filter .search_filter_box {
        max-width: unset !important;
        padding: 2px 16px;
        position: relative;
    }

    .search_item_form.maxw_60 {
        max-width: 80%;
    }
}

@media(max-width: 991px) {

    h1,
    .h1 {
        font-size: 36px;
        line-height: 1.3;
    }

    h2,
    .h2 {
        font-size: 30px;
        line-height: 1.3;
    }

    h3,
    .h3 {
        font-size: 26px;
        line-height: 1.3;
    }

    h4,
    .h4 {
        font-size: 20px;
        line-height: 1.3;
    }

    h5,
    .h5 {
        font-size: 18px;
        line-height: 1.3;
    }

    h6,
    .h6 {
        font-size: 16px;
        line-height: 1.3;
    }

    /* 
    header .search_filter_box {
        display: none;
    } */

    /* .header_flex_main .menu_part {
        position: absolute;
        top: 70px;
        left: 0px;
        width: 100%;
        padding: 20px;
        background: hwb(0 100% 0%);
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: none;
        border: 1px solid #d9d9d9;
    }

    .header_flex_main .menu_part .search_filter_box {
        max-width: unset !important;
        padding: 2px 16px;
    } */

    .shop_detials_banner .shop_text {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px !important;
    }

    .shops_details_section .custom_col_right .menu_items_card {
        border-left: none;
        margin-left: 0px;
        padding-left: 0px;
    }

    .product_list_main .product_list_item .product_text .price del {
        font-size: 16px;
        line-height: 1;
    }

    .product_list_main .product_list_item .product_text .price ins {
        font-size: 26px;
        line-height: 1;
    }

    .product_list_main .product_list_item .product_text h4,
    .product_list_main .product_list_item .product_text .price,
    .product_list_main .product_list_item .product_text .food_type {
        margin-bottom: 8px;
    }

    .shop_detials_banner .shop_logo {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        margin-top: -40px;
        left: 48px;
    }

    .cart_sidebar.sidebar_wrapper {
        margin-top: 40px;
    }

    .charity-details_hero_section .text_content_bottom,
    .event_details_hero_section .text_content_bottom {
        align-items: start;
    }

    .event_details_hero_section .text_content_bottom p {
        white-space: nowrap;
    }

    .charity_section_wrapper .charity_cards_wrapper .individual_card .img_wrapper {
        height: 240px;
    }

    .charity_section_wrapper .charity_cards_wrapper .individual_card .text_content div p {
        font-size: 16px;
    }

    .charity_section_wrapper .charity_cards_wrapper .individual_card .text_content div {
        align-items: start;
    }

    .charity_section_wrapper .charity_cards_wrapper .individual_card .text_content div span {
        font-size: 18px;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .img_wrapper,
    .popular_events .events_details_cards_wrapper .individual_card .img_wrapper {
        height: 240px;
    }

    .login_user_info .user_text {
        display: none !important;
    }

    header+.search_filter_box .ant-select .ant-select-dropdown {
        width: 100% !important;
        /* max-width: 300px; */
        left: 0px !important;
        right: 0px !important;
    }

    .order_history_page .product_list_main .product_list_item .product_text .price ins {
        font-size: 20px;
    }

    .order_history_tabs ul li a {
        padding: 12px 24px;
        font-size: 16px;
        line-height: 1;
    }


    .custom_slider_wrapper .individual_slide_linear_bg .text_content .right_text h4 {
        font-size: 28px;
        margin-bottom: 4px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span {
        font-size: 26px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span:first-child {
        font-size: 18px;
    }

    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_left,
    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_right {
        width: 40px;
        height: 40px;
        box-shadow: 0px 4px 22px rgb(140 140 140);
    }

    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_left {
        left: -20px;

    }

    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_right {
        right: -20px;

    }

    .gap {
        padding: 40px 0;
    }

    .custom_nav_arrows {
        display: flex;
        align-items: center;
        gap: 18px;
    }

    .custom_nav_arrows button.custom_nav_arrow_left,
    .custom_nav_arrows button.custom_nav_arrow_right {
        position: unset !important;
        width: 40px;
        height: 40px;
        transform: unset !important;
    }

    .custom_slider_wrapper .individual_slide_linear_bg {
        padding: 120px 50px;
    }

    .header_flex_main {
        gap: 24px;
    }

    .search_item_form.maxw_60 {
        max-width: 100%;
    }

    .landing_section {
        min-height: 550px;
    }

    .landing_section .landing_section_main h1.fs48 {
        font-size: 36px !important;
    }
}

@media(max-width: 767px) {
    .offers_slider_main .offers_card .text_part p {
        -webkit-line-clamp: 2;
    }

    .order_ctg_filter_slider a .ctg_icon {
        width: 80px;
        height: 80px;
        border-radius: 80px;
    }

    .product_list_main .product_list_item.gap-4 {
        gap: 14px !important;
    }

    .product_list_main .product_list_item .product_image {
        width: 124px;
        height: 124px;
    }

    .form-group .form-control {
        padding: 14px 16px;
    }

    .manage_address_card {
        padding: 14px;
        border-radius: 14px;
    }

    .manage_address_card .custom_radio_group {
        padding-left: 26px;
    }

    .manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label:before,
    .manage_address_card .custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label:before {
        width: 16px;
        height: 16px;
    }

    .manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label:after,
    .manage_address_card .custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label:after {
        width: 8px;
        height: 8px;
        top: calc(50% - 4px);
        left: 4px;
    }

    .manage_address_card .custom_radio_group .radio_input[type="radio"]+.radio_label h6,
    .manage_address_card .custom_radio_group .radio_input[type="radio"]+.radio_label p {
        font-size: 14px !important;
    }

    .manage_address_card .custom_radio_group .radio_input[type="radio"]+.radio_label h6 svg,
    .manage_address_card .custom_radio_group .radio_input[type="radio"]+.radio_label a svg {
        width: 16px;
        height: 16px;
    }

    .manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label,
    .manage_address_card .custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label {
        padding: 14px;
    }

    .manage_address_card .custom_radio_group .radio_input[type="radio"]+.radio_label .address_box_action {
        gap: 8px;
    }

    .ant-modal-content .card_content .circular_image_wrapper {
        width: 140px;
        height: 140px;
    }

    .custom_drawer_menu .ant-drawer-content-wrapper {
        width: 320px !important;
    }

    :where(.css-dev-only-do-not-override-1km3mtt).ant-drawer .ant-drawer-header,
    :where(.css-dev-only-do-not-override-1km3mtt).ant-drawer .ant-drawer-body,
    .custom_drawer_menu.ant-drawer .ant-drawer-footer {
        padding: 14px;
    }

    .order_history_page .product_list_main .product_list_item .product_text .fs20 {
        font-size: 14px !important;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .main_text h4,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .main_text h4 {
        font-size: 18px;
        margin-bottom: 4px;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .main_text p,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .main_text p {
        font-size: 14px;
        line-height: 1.3;
        gap: 4px;
    }


    .events_happening_near_you .events_details_cards_wrapper .individual_card .img_wrapper .event_date_absolute,
    .popular_events .events_details_cards_wrapper .individual_card .img_wrapper .event_date_absolute {
        top: 10px;
        left: 10px;
        padding: 4px 8px;
        border-radius: 5px;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .img_wrapper .event_date_absolute span.numeric_value,
    .popular_events .events_details_cards_wrapper .individual_card .img_wrapper .event_date_absolute span.numeric_value {
        font-size: 18px;
        font-weight: 600;
    }

    .custom_slider_wrapper .individual_slide_linear_bg {
        padding: 70px 30px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text {
        padding: 10px 12px;
    }

    .gap {
        padding: 30px 0;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content {
        flex-direction: column;
        gap: 16px;
    }

    .products_card_image {
        border-radius: 4px;
        height: 200px;
    }

    .shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .product_text .right_part .button {
        padding: 10px 18px;
        font-size: 16px;
    }

    .header_flex_main .menu_part_wrapper .restaurants_search_filter,
    .header_flex_main .menu_part_wrapper .location_search_filter {
        top: 58px !important;
    }

    .main-content-sections {
        margin-top: 68px !important;
    }

    /* .foods_menu_categories .ctg_item .ctg_icon {
        width: 80px !important;
        height: 80px !important;
    } */

}

@media(max-width: 575px) {
    .gap20 {
        gap: 14px;
    }

    .gap30 {
        gap: 14px;
    }

    .bag i {
        width: 24px;
        height: 24px;
    }

    .offers_slider_main .offers_card {
        padding: 14px;
        border-radius: 10px;
    }

    .slide_gap30 .slick-slide {
        padding-left: 8px;
        padding-right: 8px;
    }

    .slide_gap30 .slick-track {
        margin-left: -8px;
        margin-right: -8px;
    }

    .shops_details_section .menu_items_card .menu_items_list {
        padding-right: 0px;
    }

    .products_grids_main .products_cards {
        padding: 8px 8px 14px;
        border-radius: 8px;
    }

    .product_list_main .product_list_item .product_text {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .product_list_main .product_list_item .product_text .price ins {
        font-size: 20px;
    }

    .product_list_main .product_list_item .product_text .rating_star_part span {
        font-size: 16px;
        line-height: 1;
    }

    .qty_button {
        gap: 8px;
        max-width: 100px;
        padding: 2px 6px;
    }

    .product_list_main .product_list_item .product_text .rating_star_part img {
        width: 16px;
    }

    .product_list_main .product_list_item .product_text .rating_star_part .rating_star {
        gap: 4px !important;
    }

    .product_list_main li {
        padding-bottom: 14px;
        padding-top: 14px;
    }

    .shop_detials_banner .image {
        border-radius: 10px;
    }

    .shop_detials_banner .image img {
        min-height: 200px;
    }

    .shop_detials_banner .shop_logo {
        left: 30px;
    }

    .sidebar_wrapper .sidebar_widget {
        padding: 14px;
        border-radius: 10px;
    }

    .payment_successful_modal_wrapper .ant-modal-content,
    .payment_failed_modal_wrapper .ant-modal-content {
        padding: 20px;
    }

    .ant-modal-content .card_content .circular_image_wrapper {
        margin-bottom: 30px;
    }

    .ant-modal-content .card_content .popup_status_discription {
        font-size: 18px;
    }

    .ant-modal-content .card_content .popup_status_title {
        font-size: 26px;
    }

    .ant-modal-content .card_content .circular_image_wrapper {
        width: 120px;
        height: 120px;
    }

    .payment_successful_modal_wrapper .ant-modal-content,
    .payment_failed_modal_wrapper .ant-modal-content {
        border-radius: 20px;
    }

    .event_details_hero_section .text_content_bottom h3 {
        font-size: 30px;
    }

    .event_details_hero_section .text_content_bottom p {
        white-space: nowrap;
        font-size: 12px;
    }

    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_left,
    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_right {
        width: 30px;
        height: 30px;
        box-shadow: 0px 4px 22px rgb(140 140 140);
    }

    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_left {
        left: 0px;
        bottom: -60px;
        top: unset;
    }

    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_right {
        left: 40px;
        bottom: -60px;
        top: unset;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text {
        padding: 6px 10px;
        border-radius: 5px;
    }

    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_left svg,
    .custom_slider_wrapper .custom_nav_arrows button.custom_nav_arrow_right svg {
        width: 16px;
        height: 16px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg {
        padding: 40px 24px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span {
        font-size: 20px;
        line-height: 1;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span:first-child {
        font-size: 16px;
        line-height: 1;
    }

    .cart_list_drawer .product_list_main .product_list_item .product_text .price del {
        font-size: 14px;
        line-height: 1;
    }

    .cart_list_drawer .product_list_main .product_list_item .product_text .price ins {
        font-size: 16px;
        line-height: 1;
    }

    .cart_list_drawer .product_list_main .product_list_item .product_text h4,
    .cart_list_drawer .product_list_main .product_list_item .product_text .price {
        margin-bottom: 4px;
    }

    .cart_list_drawer .product_list_main li .product_list_item.gap-3 {
        gap: 8px !important;
    }

    .section_title {
        margin-bottom: 16px;
    }

    .section_title h2 {
        font-size: 26px;
        line-height: 1.3;
    }

    .products_card_text h5 a {
        font-size: 16px;
    }

    .products_grids_main .products_cards .products_card_image.mb20 {
        margin-bottom: 10px !important;
    }

    .product_action .wishlist_icon {
        top: 8px;
        right: 8px;
        width: 24px;
        height: 24px;
    }

    .order_history_page .product_list_main .product_list_item .product_image {
        width: 80px;
        height: 80px;
    }

    .order_history_page .product_list_main .product_list_item .product_text {
        gap: 0px;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content .main_text p,
    .popular_events .events_details_cards_wrapper .individual_card .text-content .main_text p,
    .events_happening_near_you .events_details_cards_wrapper .individual_card .text-content,
    .popular_events .events_details_cards_wrapper .individual_card .text-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .events_happening_near_you .events_details_cards_wrapper .individual_card,
    .popular_events .events_details_cards_wrapper .individual_card {
        padding: 8px 8px 14px;
        border-radius: 10px;
    }

    .cookies_banner_wrapper {
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
    }

    .header_flex_main .menu_part {
        padding: 12px;
    }

    .header_flex_main {
        gap: 14px;
    }


}

@media(max-width: 480px) {
    .offers_slider_main .offers_card .text_part h3 {
        font-size: 18px;
        margin-bottom: 8px !important;
    }

    .offers_slider_main .offers_card .text_part p {
        font-size: 12px;
        margin-bottom: 8px;
    }

    .offers_slider_main .offers_card .text_part .button {
        padding: 8px 16px;
        font-weight: 500;
        font-size: 12px;
    }

    .products_cards .products_card_text {
        flex-wrap: wrap;
    }

    .products_card_text .left_part {
        width: 100%;
    }

    .products_grids_main .row {
        --bs-gutter-x: 14px;
    }

    .product_list_item.flex_item_cb {
        align-items: flex-start;
    }

    .product_list_main .product_list_item .product_image {
        width: 80px;
        height: 80px;
        border-radius: 10px;
    }

    .payment_successful_modal_wrapper .ant-modal-content,
    .payment_failed_modal_wrapper .ant-modal-content {
        padding: 15px;
    }

    .ant-modal-content .card_content .circular_image_wrapper {
        margin-bottom: 20px;
    }

    .ant-modal-content .card_content .popup_status_discription {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .ant-modal-content .card_content .popup_status_title {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .ant-modal-content .ant-modal-body .card_content button {
        margin-top: 20px !important;
    }

    .payment_successful_modal_wrapper .ant-modal-content,
    .payment_failed_modal_wrapper .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-content .ant-modal-body .card_content h5.starting_heading_center_32 {
        font-size: 28px;
        line-height: 1.5;
    }

    .feedback_modal_wrapper .ant-modal-content {
        padding: 70px 30px 30px 30px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content .right_text p span {
        font-size: 10px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text {
        padding: 10px 12px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span:first-child {
        font-size: 16px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span {
        font-size: 18px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg .text_content .right_text h4 {
        font-size: 22px;
    }

    .custom_slider_wrapper .individual_slide_linear_bg {
        padding: 50px 20px;
    }

    .products_card_image {
        height: 150px;
    }

    .shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .product_text .right_part .button {
        padding: 8px 16px;
        font-size: 14px;
        border-radius: 8px;
    }

    .header_flex_main .menu_part {
        padding: 8px;
    }
}

@media(max-width: 390px) {
    .buttons_wrapper {
        flex-direction: column;
        gap: 12px !important;
    }

    .buttons_wrapper .button_outside {
        width: 100% !important;
    }
}