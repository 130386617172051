@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&amp;display=swap');

@font-face {
    font-family: 'Metropolis Semi';
    src: url('../fonts/Metropolis-SemiBoldItalic.eot');
    src: url('../fonts/Metropolis-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Medium.eot');
    src: url('../fonts/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Medium.woff2') format('woff2'),
        url('../fonts/Metropolis-Medium.woff') format('woff'),
        url('../fonts/Metropolis-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-MediumItalic.eot');
    src: url('../fonts/Metropolis-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-MediumItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-MediumItalic.woff') format('woff'),
        url('../fonts/Metropolis-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-LightItalic.eot');
    src: url('../fonts/Metropolis-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-LightItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-LightItalic.woff') format('woff'),
        url('../fonts/Metropolis-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Semi';
    src: url('../fonts/Metropolis-SemiBold.eot');
    src: url('../fonts/Metropolis-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-SemiBold.woff2') format('woff2'),
        url('../fonts/Metropolis-SemiBold.woff') format('woff'),
        url('../fonts/Metropolis-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraBoldItalic.eot');
    src: url('../fonts/Metropolis-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Regular.eot');
    src: url('../fonts/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Regular.woff2') format('woff2'),
        url('../fonts/Metropolis-Regular.woff') format('woff'),
        url('../fonts/Metropolis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraLightItalic.eot');
    src: url('../fonts/Metropolis-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Metropolis-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Light.eot');
    src: url('../fonts/Metropolis-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Light.woff2') format('woff2'),
        url('../fonts/Metropolis-Light.woff') format('woff'),
        url('../fonts/Metropolis-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraBold.eot');
    src: url('../fonts/Metropolis-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-ExtraBold.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraBold.woff') format('woff'),
        url('../fonts/Metropolis-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-BoldItalic.eot');
    src: url('../fonts/Metropolis-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-BoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-BoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Black.eot');
    src: url('../fonts/Metropolis-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Black.woff2') format('woff2'),
        url('../fonts/Metropolis-Black.woff') format('woff'),
        url('../fonts/Metropolis-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Bold.eot');
    src: url('../fonts/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Metropolis-Bold.woff2') format('woff2'),
        url('../fonts/Metropolis-Bold.woff') format('woff'),
        url('../fonts/Metropolis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@media(max-width: 2000px) and (min-width: 1600px) {
    .container {
        max-width: 1400px;
    }
}

body {
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Metropolis';
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

.main-content-sections {
    margin-top: 80px;
}

p {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    color: #787878;
}

* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #333230;
    font-size: 16px;
}

a:hover {
    color: #333230;
}

h1 {
    font-size: 60px;
    line-height: 80px;
    color: #333230;
}

h2 {
    line-height: 58px;
    font-size: 48px;
    margin: 0;
    color: #333230;
}

h3 {
    line-height: 46px;
    font-size: 36px;
    margin: 0;
    color: #333230;
}

h4 {
    font-size: 24px;
    line-height: 34px;
    color: #333230;
}

h5 {
    line-height: 32px;
    font-size: 22px;
    color: #333230;
}

h6 {
    line-height: 28px;
    font-size: 18px;
    color: #333230;
}

a {
    color: black;
}

.gap {
    padding: 100px 0;
}

.gap50 {
    padding: 50px 0;
}

.gap60 {
    padding: 60px 0;
}

.no-top {
    padding-top: 0;
}

.no-bottom {
    padding-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    display: block;
}

figure {
    overflow: hidden;
}

/*------------------------        navbar      --------------------------*/
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: -1;
    opacity: 0;
    transition: 1s all;
}

.navbar {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px;
}

.navbar-logo {
    color: #ff3f34;
    text-decoration: none;
    font-size: 25px;
    padding: 0px 20px;
}

img.logo {
    max-width: 154px;
    height: auto;
}

.navbar-links {
    list-style-type: none;
    display: flex;
}

.navbar-links li a {
    display: block;
    text-decoration: none;
    color: #363636;
    padding: 8px 20px;
    transition: 0.4s all;
    font-size: 16px;
    position: relative;
}

.navbar-links li.navbar-dropdown {
    position: relative;
}

.navbar-links li.navbar-dropdown:hover .dropdown {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    z-index: 1111;
}

.navbar-links li.navbar-dropdown .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 20px 0;
    top: 100%;
    transform: translateY(50px);
    left: 0;
    width: 250px;
    background-color: #fff;
    box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 111;
    transition: 0.4s all;
}

.navbar-links li.navbar-dropdown .dropdown a {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    border: 0;
}

.navbar-dropdown .dropdown a:hover {
    padding-left: 30px;
}

.navbar-links li a:hover {
    color: #F29F05;
}

.navbar-links li.active>a {
    color: #F29F05;
}

.navbar-links li>a:after {
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 0px;
    height: 2px;
    width: auto;
    background: transparent;
    transition: 0.4s all;
}

.navbar-links li.active>a:after {
    background: #F29F05;
}

.navbar-links li a {
    border: 1px solid transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

header {
    position: absolute;
    padding: 20px 0;
    width: 100%;
    z-index: 1;
}

/*------------------------        navbar end      --------------------------*/
/*-------------     footer  start    --------------*/
.footer {
    background-image: url("../images/categories_bg.jpg");
    background-position: center;
    background-size: cover;
    padding: 40px 0px 0px 0px;
}

.footer .footer-upper {
    padding-bottom: 24px;
}

.footer .footer_menu_wrapper .menu_title {
    font-size: 18px;
    font-weight: 600;
    color: #333230;
    margin-bottom: 12px;
}

.footer .footer_menu_wrapper .menu ul li:not(:last-child) {
    margin-bottom: 12px;
}

.footer .footer_menu_wrapper .menu ul li a,
.footer .footer_menu_wrapper .form-group label {
    font-size: 16px;
    font-weight: 500;
    color: #818181;
}

.footer .footer_menu_wrapper .menu .subscribe_form .button {
    width: unset !important;
    height: unset !important;
    padding: 12px 16px !important;
    font-size: 16px;
}

.footer .footer_menu_wrapper .menu .subscribe_form .form-control {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 10px 50px;
}

.footer .footer_menu_wrapper .menu .subscribe_form .form-control.ant-input-affix-wrapper-focused {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 10px 50px !important;
}

.footer .footer-lower {
    padding: 16px 0px;
    border-top: 1px solid #82828233;
}

.footer .footer-lower .footer_menu_wrapper .menu ul li a {
    font-size: 14px;
}

/*-------------     footer  end   --------------*/
/*------------------------        restaurant      --------------------------*/
.restaurant h1 {
    font-weight: bold;
    width: 92%;
    text-transform: capitalize;
}

.restaurant p {
    width: 73%;
}

.restaurant .nice-select {
    color: #CFCFCF;
    border: 0;
    box-shadow: -1px 15px 26px -4px rgba(161, 151, 151, 0.15);
    -webkit-box-shadow: -1px 15px 26px -4px rgba(161, 151, 151, 0.15);
    -moz-box-shadow: -1px 15px 26px -4px rgba(161, 151, 151, 0.15);
    height: 55px;
    width: 60%;
    font-size: 18px;
}

.restaurant .nice-select:after {
    top: 46%;
    height: 10px;
    width: 10px;
    right: 5%;
}

.restaurant .nice-select.Advice {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.restaurant .nice-select .option:hover,
.restaurant .nice-select .option.focus,
.restaurant .nice-select .option.selected.focus {
    background: #f29f05;
    color: black;
}

.restaurant .nice-select.open .list {
    width: 100%;
}

.nice-select-one {
    margin-top: 40px;
    display: flex;
    align-items: center;
}

.hero-section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.hero-section.about.blog-page {
    overflow: initial;
    background-repeat: no-repeat;
    background-position: top;
    background-size: auto;
}

.img-restaurant {
    position: relative;
}

.wilmington img {
    width: auto;
    border-radius: 10px;
    padding-right: 15px;
}

.wilmington h6 {
    font-size: 18px;
    line-height: 14px;
    font-weight: bold;
}

.wilmington p {
    font-size: 12px;
}

.wilmington {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    top: 67%;
    width: 50%;
}

.wilmington.location-restaurant {
    top: 28%;
    right: 0;
    width: 35%;
}

.wilmington.location-restaurant p {
    line-height: 5px;
}

.wilmington.location-restaurant i {
    font-size: 18px;
    background-color: #f29f05;
    color: white;
    border-radius: 33px;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 13px;
    padding: 0;
}

/*------------------------    button   --------------------------*/
.button {
    text-align: center;
    color: #333230;
    text-transform: capitalize;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: #333230;
    height: auto;
    padding: 15px 24px;
    border-radius: 10px;
    white-space: nowrap;
}


.button.trasns_bordered {
    border: 2px solid #adadad;
}

.button-2 {
    color: #333230;
    border: 2px solid #FFBD05;
    background-image: -webkit-linear-gradient(30deg, #FFBD05 50%, transparent 50%);
    background-image: linear-gradient(30deg, #FFBD05 50%, transparent 50%);
    background-size: 1200px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    line-height: 1;
}

.button-2.button-outlined {
    border: 2px solid #FFBD05 !important;
    background-color: transparent !important;
    background-position: 100%;
    font-size: 14px !important;
    font-weight: 500;
}

.button-2.button-outlined:hover {
    background-position: 0%;
    color: #ffffff !important;
}

.button-2:hover {
    background-position: 100%;
    background-color: transparent;
}

/*------------- button end --------*/
.bag i {
    background-color: white;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 10px;
    margin-right: 15px;
}

.bag {
    text-align: end;
}

.bag .button {
    font-size: 16px;
    line-height: 1;
    padding: 10px 14px;
    font-weight: 500;
}

/*--------------       Works Section    ----------------*/
.hading {
    text-align: center;
}

.hading h2 {
    font-weight: bold;
    text-transform: capitalize;
}

.hading p {
    padding-top: 30px;
    width: 66%;
    margin: auto;
}

.work-card {
    text-align: center;
    padding-top: 50px;
}

.work-card span {
    font-size: 36px;
    color: #cfcfcf;
}

.work-card h4 {
    font-size: 24px;
    font-weight: bold;
    padding: 25px 0;
    display: block;
    margin: 0;
}

.work-card p {
    width: 94%;
    margin: auto;
    font-size: 16px;
    line-height: 26px;
}

/*--------------       best-restaurants    ----------------*/
.city-restaurants h2 {
    width: 83%;
    font-weight: bold;
    padding-bottom: 20px;
}

.city-restaurants p {
    width: 80%;
}

.cafa h4 {
    margin: 0;
}

.cafa h4 a {
    font-weight: bold;
    font-size: 24px;
}

.cafa p {
    font-size: 16px;
}

.cafa-button a {
    padding: 5px 15px;
    border: 1px solid #f29f05;
    border-radius: 10px;
    font-size: 14px;
}

.cafa-button a:hover {
    color: white;
}

.logos-card {
    display: flex;
    align-items: start;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
}

.logos-card img {
    border-radius: 15px;
}

.cafa-button {
    margin-top: 15px;
    margin-bottom: 20px;
}

.cafa {
    margin-left: 30px;
}

.logos-card.three,
.logos-card.two {
    margin-top: 15px;
}

.logos-card.three {
    background-color: transparent;
}

.button-gap {
    text-align: center;
    padding-top: 40px;
}

.button-gap a i {
    padding-left: 15px;
}

a.button-2.non {
    background-image: -webkit-linear-gradient(30deg, #f29f0500 50%, transparent 50%);
}

a.button-2.non:hover {
    color: #fff;
    border: 3px solid #F29F05;
    background-image: -webkit-linear-gradient(30deg, #F29F05 50%, transparent 50%);
    background-image: linear-gradient(30deg, #F29F05 50%, transparent 57%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

a.button-2.non {
    background-position: 100%;
}

/*-------------     your-favorite-food      --------------*/
.your-favorite-food {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.food-photo-section {
    position: relative;
}

.food-photo-section a i {
    padding-right: 10px;
}

.food-photo-section a {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
}

.food-photo-section a.one {
    top: 7%;
    right: 5%;
}

.food-photo-section a.two {
    top: 20%;
    right: 5%;
}

.food-photo-section img {
    width: 100%;
}

.food-photo-section {
    width: 100%;
    height: 100%;
    text-align: center;
}

.food-photo-section .mobile_img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.food-photo-section .mobile_img2 {
    width: 73%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.food-photo-section a.three {
    top: 33%;
    right: 5%;
}

.food-content-section p {
    padding: 20px 0 30px;
    width: 90%;
}

.food-content-section h2 {
    font-weight: bold;
    width: 80%;
}

/*-------------     counters-section      --------------*/

.counters-section h2 {
    font-weight: bold;
}

.count-time {
    display: flex;
    padding: 50px;
    box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.count-time span {
    font-weight: bold;
    font-size: 55px;
    padding-right: 20px;
    line-height: 1;
    text-transform: uppercase;
}

.count-time h2 {
    font-size: 60px;
    padding-right: 20px;
}

.count-time.sp h2 {
    padding-right: 0;
}

/*-------------     reviews      --------------*/
.reviews-img {
    position: relative;
}

.reviews-img i {
    position: absolute;
    top: 8%;
    font-size: 85px;
    background-color: white;
    left: 3%;
    padding: 30px;
    border-radius: 88px;
    box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
}

.reviews-img i:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 30px solid #fff;
    border-bottom: 25px solid transparent;
    transform: rotate(45deg);
    top: 70%;
    left: 80%;
}

.reviews-img img {
    width: 100%;
}

.reviews-content h2 {
    font-weight: bold;
    width: 80%;
}

.thomas {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.reviews-content h4 {
    color: #787878;
    padding-top: 40px;
    width: 86%;
    font-weight: 400;
}

.thomas img {
    border-radius: 60px;
    margin-right: 30px;
    width: auto;
}

.custome.owl-carousel .owl-item img {
    width: auto;
}

.custome {
    position: relative;
}

.custome .owl-dots {
    display: none;
}

.custome.owl-theme .owl-nav {
    position: absolute;
    bottom: -26%;
}

.custome.owl-carousel .owl-nav button.owl-next,
.custome.owl-carousel .owl-nav button.owl-prev,
.custome.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    padding: 0 !important;
    font: inherit;
    border: 1px solid #363636;
    height: 40px;
    width: 40px;
    border-radius: 21px;
    color: #363636;
}

/*-------------     Join Partnership Section      --------------*/
.join-partnership h2 {
    text-align: center;
    font-weight: bold;
    color: white;
}

.join-img {
    padding-top: 80px;
    padding-right: 50px;
    position: relative;
}

.Join-courier {
    position: absolute;
    left: 10%;
    bottom: 15%;
    right: 10%;
}

a.button.button-2 i {
    padding-left: 10px;
}

.Join-courier h3 {
    color: white;
    font-weight: bold;
    padding-bottom: 15px;
}

.join-img img {
    border-radius: 20px;
    width: 100%;
}

/* .join-img:before {
    content: "";
    position: absolute;
    height: 84%;
    width: 94%;
    border: 7px solid #f29f05;
    border-radius: 20px;
    left: 4%;
    top: 8%;
} */

.join-img {
    border: 4px solid #ffbd05;
    border-radius: 30px;
    padding: 14px;
    margin-bottom: 30px;
}

/*-------------     news-section      --------------*/
.news-section h2 {
    font-weight: bold;
    width: 33%;
    padding-bottom: 20px;
}

.news-posts-one img {
    border-radius: 20px;
    width: 100%;
}

.news-posts-one {
    padding: 25px;
    box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px -1px 30px 4px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    position: relative;
}

.quickeat {
    position: absolute;
    top: 8%;
    left: 8%;
}

.news-posts-one .quickeat a {
    padding: 5px 15px;
    border-radius: 10px;
    color: white;
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
}

.news-posts-one h3 {
    font-weight: bold;
    padding: 40px 0 20px;
}

.news-posts-one a {
    color: var(--color-theme);
    font-weight: 600;
}

.news-posts-one a i {
    padding-left: 10px;
}

.news-posts-one p {
    font-size: 16px;
    padding-bottom: 20px;
    width: 95%;
}

ul.data li {
    padding-right: 20px;
}

ul.data h6 {
    color: #cfcfcf;
    font-size: 14px;
}

ul.data {
    display: flex;
    align-items: center;
    border: 1px solid #F2F2F2;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    padding-top: 20px;
    margin-top: 20px;
}

ul.data h6 i {
    padding-right: 10px;
}

.news-post-two {
    display: flex;
    align-items: center;
    padding-bottom: 35px;
}

.news-post-two.end {
    padding-bottom: 0;
}

.news-post-two img {
    border-radius: 20px;
}

.news-post-two .quickeat {
    position: relative;
    left: 0;
    top: 0;
}

.news-post-two .quickeat a {
    color: white;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 14px;
}

.news-post-two h6 {
    margin-bottom: 0;
}

.news-post-two h6 a {
    margin-top: 15px;
    display: block;
    font-size: 18px;
    font-weight: bold;
    width: 77%;
}

.news-post-two-data {
    margin-left: 30px;
}

.news-post-two-data p {
    font-size: 16px;
    line-height: 26px;
}

.news-post-two-data ul.data {
    padding-top: 6px;
    margin-top: 7px;
}

/*-------------     subscribe section     --------------*/
.img-subscribe img {
    width: 100%;
}

.get-the-menu h2 {
    font-weight: bold;
}

.get-the-menu form {
    display: flex;
    margin-top: 30px;
    position: relative;
}

.get-the-menu input[type="text"] {
    outline: none;
    width: 55%;
    margin-right: 20px;
    background-color: white;
    border: 0;
    padding-left: 40px;
    color: #000000;
    border-radius: 10px;
}

.get-the-menu input[type="text"]::placeholder {
    color: #494949;
}

.get-the-menu form i {
    position: absolute;
    top: 0;
    left: 2%;
    color: #cfcfcf;
    height: 100%;
    display: flex;
    align-items: center;
}



/*-------------------------------  about pags  --------------------------- */
/*-----------  about hero  ----------------- */
ul.crumbs li {
    padding-right: 15px;
}

ul.crumbs li a {
    color: #cfcfcf;
}

ul.crumbs li a i {
    padding-right: 10px;
    font-size: 10px;
}

ul.crumbs li.two a {
    color: #363636;
}

ul.crumbs li.two i {
    padding-right: 8px;
    font-size: 12px;
}

.about-text h2 {
    font-weight: bold;
    width: 61%;
    line-height: 130%;
    padding-top: 40px;
    padding-bottom: 20px;
}

.about-text p {
    width: 82%;
}

.about-img img {
    border-radius: 50px;
    transform: rotate(13deg);
    width: 500px;
    height: 500px;
    margin-left: 44px;
}

.about-img {
    position: relative;
    padding: 60px 0;
}

.about-img:before {
    content: "";
    position: absolute;
    border: 7px white solid;
    height: 500px;
    width: 500px;
    border-radius: 50px;
    z-index: 0;
    left: 5%;
}

.our-mission-text h2 {
    font-weight: bold;
    padding-bottom: 30px;
    margin: 0;
}

/*----------------    restaurants-girl-img      ------------------------*/
.restaurants-girl-img.food-photo-section a.one {
    left: 10%;
    display: inline-table;
    top: 19%;
}

.restaurants-girl-img.food-photo-section a.two {
    left: 15%;
    display: inline-table;
    top: 34%;
}

.restaurants-girl-img.food-photo-section a.three {
    left: 10%;
    display: inline-table;
    top: 49%;
}

.restaurants-girl-img.food-photo-section a.for {
    left: 15%;
    display: inline-table;
    top: 64%;
}

/*-------------     cards-section      --------------*/
.card-text-data {
    padding: 40px;
    border-radius: 20px;
}

.card-text-data p {
    font-size: 16px;
}

.card-text-data:hover {
    background: linear-gradient(145deg, #FFBB45, #FF820B);
}

.card-text-data img.off {
    display: none;
}

.card-text-data:hover img.off {
    display: block;
}

.card-text-data:hover img.on {
    display: none;
}

.card-text-data:hover p,
.card-text-data:hover h3 {
    color: white;
}

.card-text-data h3 {
    font-weight: bold;
    padding: 20px 0;
    width: 80%;
}

.card-text-data:hover svg {
    fill: white;
}

.card-text-data svg {
    fill: #f5a905;
}

/*------------------     video-section     ------------------*/
ul.paragraph li h5 {
    font-size: 16px;
    margin: 0;
    color: #787878;
}

ul.paragraph li {
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.wait-a-minute h2 {
    font-weight: bold;
    width: 80%;
}

.wait-a-minute p {
    width: 80%;
    padding: 20px 0;
}

.wait-a-minute h6 {
    font-weight: bold;
}

ul.paragraph li i {
    padding-right: 10px;
}

.video-section-img {
    position: relative;
    padding: 40px 0;
}

.video-section-img img {
    border-radius: 40px;
}

.video-section-img:before {
    content: "";
    position: absolute;
    border: 7px #F29F05 solid;
    height: 567px;
    width: 579px;
    border-radius: 50px;
    z-index: -1;
    left: 15%;
    transform: rotate(13deg);
}

.video-section-img i {
    position: absolute;
    z-index: 111;
    left: 40%;
    color: #ffffff;
    top: 50%;
    font-size: 30px;
    transform: translate(-50%, -50%);
    background-color: #f29f05;
    height: 70px;
    width: 70px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 57px;
}

.counter-hading {
    margin-bottom: 30px;
}

.count-time h2 {
    font-weight: bold;
}

.counter-hading h2 {
    font-weight: bold;
}

/*-------------     Our Team Section      --------------*/
.our-team h4 a {
    font-weight: bold;
    padding-top: 20px;
    margin: 0;
    font-size: 24px;
    display: block;
}

.our-team {
    position: relative;
    text-align: center;
}

.our-team p {
    padding-top: 10px;
    width: 90%;
    margin: auto;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
}

.our-team ul.social-media {
    padding: 0;
    padding-top: 20px;
    justify-content: center;
}

.our-team ul.social-media li a i {
    border: 1px solid #787878;
    color: #787878;
}

.our-team ul.social-media li a i:hover {
    border: 1px #f29f05;
    color: white;
}

.our-team:before {
    z-index: -1;
    content: "";
    position: absolute;
    height: 400px;
    width: 440px;
    background-color: #fcfcfc;
    top: 6.5%;
    border-radius: 81px;
}

.our-team-section .hading p {
    padding-bottom: 40px;
    width: 50%;
}

.about-counters-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/*--------------      our-partners-section      --------------*/
.our-partners-section .hading h2 {
    padding-bottom: 60px;
}

.logo-img {
    text-align: center;
    display: inline-block;
}

.logo-img h5 {
    font-size: 16px;
    font-weight: bold;
    color: #c7c7c7;
}

.owl-carousel .owl-item img {
    width: auto;
}

.logodata .owl-dots {
    display: none;
}

/*-------------     service-shows      --------------*/
.good-taste h2 {
    font-weight: bold;
    padding-bottom: 30px;
    margin: 0;
}

.good-taste {
    width: 63%;
    margin: auto;
    margin-left: 211px;
}

.comment-data {
    display: flex;
}

.author-text.item {
    margin-left: -15%;
    width: 91%;
}

.author-text .thomas {
    margin-top: 10px;
    margin-left: 24px;
}

.author-text .thomas img {
    margin-top: -40px;
    padding-right: 0;
    margin-right: 30px;
    box-shadow: 0px -7px 6px 4px rgb(161 151 151 / 15%);
    -webkit-box-shadow: 0px -7px 6px 4px rgb(161 151 151 / 15%);
    -moz-box-shadow: 0px -7px 6px 4px rgb(161 151 151 / 15%);
}

.comment-slide .owl-item.cloned {
    opacity: 0.5;
}

.comment-slide .owl-item.cloned.active {
    opacity: 1;
}

.comment-slide.owl-carousel .owl-nav.disabled {
    display: block;
    position: absolute;
    left: -33%;
    top: 81%;
}

.comment-slide.owl-carousel .owl-nav button.owl-next,
.comment-slide.owl-carousel .owl-nav button.owl-prev,
.comment-slide.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    padding: 0 !important;
    font: inherit;
    border: 1px solid #363636;
    height: 40px;
    width: 40px;
    border-radius: 21px;
    color: #363636;
}

.author-text p {
    padding: 30px 25px;
    font-size: 16px;
    padding-bottom: 60px;
    border-radius: 20px;
    margin-top: 20px;
}

.logo-img {
    display: block;
}

.logodata.owl-carousel .owl-item img {
    width: 140px;
    margin: auto;
    opacity: .5;
}

.logodata.owl-carousel .owl-item img:hover {
    opacity: 1;
}

/*---------.subscribe-section-about ------------*/
.subscribe-section.about {
    padding: 120px 0;
}

.subscribe-section.about .get-the-menu form {
    justify-content: center;
}

.subscribe-section.about .get-the-menu input[type="text"] {
    outline: none;
    width: 35%;
}

.subscribe-section.about .get-the-menu h2 {
    color: white;
    width: 55%;
    text-align: center;
    margin: auto;
}

.subscribe-section.about {
    position: relative;
    overflow: hidden;
    padding: 110px 0;
}

.subscribe-section.about:before {
    content: "";
    background-color: #f29f05c4;
    width: 100%;
    height: 700px;
    position: absolute;
    top: 0;
    z-index: 0;
}

.subscribe-section.about .get-the-menu {
    position: relative;
}

.subscribe-section.about .get-the-menu form i {
    position: absolute;
    top: 0%;
    left: 28%;
    color: #cfcfcf;
}

.subscribe-section.about .button-2 {
    color: white;
    border: 3px solid #000;
    background-image: -webkit-linear-gradient(30deg, #000 50%, transparent 50%);
    background-image: linear-gradient(30deg, #000000 50%, transparent 50%);
}

/*------------   restaurant page--------------*/
.hero-section.about .restaurant .nice-select {
    width: 80%;
    padding-left: 20px;
}

.banner-img {
    padding: 40px 0;
}

.choose-lunches h3 {
    font-size: 45px;
    font-weight: bold;
    padding-bottom: 30px;
    padding-top: 15px;
}

.choose-lunches h2 {
    font-weight: bold;
}

.choose-lunches {
    padding-left: 40px;
    padding-top: 35px;
}

.banner-img h4 {
    font-size: 14px;
    line-height: 20px;
    height: 70px;
    width: 180px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    position: relative;
}

.chevron {
    width: 0em;
    height: 5em;
    position: absolute;
    right: 0;
}

.chevron::before,
.chevron::after {
    content: "";
    position: absolute;
    width: 1.5em;
    height: 2.5em;
    left: -6px;
    background: #ffffff;
}

.chevron::before {
    top: 0;
    -webkit-transform: skew(20deg, 0deg);
}

.chevron::after {
    bottom: 0;
    -webkit-transform: skew(-20deg, 0deg);
}

.chevron--left::before {
    -webkit-transform: skew(-20deg, 0deg);
}

.chevron--left::after {
    -webkit-transform: skew(20deg, 0deg);
}

.banner-wilmington {
    display: flex;
    align-items: center;
    margin-left: 35px;
}

.banner-logo {
    display: flex;
}

.banner-wilmington img {
    border-radius: 15px;
}

.banner-wilmington h6 {
    font-size: 18px;
    margin: 0;
    font-weight: bold;
    padding-left: 15px;
}

/*-------- restaurant logos-card    --------*/

.logos-card.restaurant-page.two {
    margin-top: 25px;
}

/*----------     restaurant logos-card   -------*/
.logo-detail {
    display: flex;
    align-items: start;
}

.logo-detail img {
    width: 80px;
    height: auto;
    border-radius: 15px;
    margin-top: 13px;
}

.logo-detail h2 {
    padding: 0;
}

.logo-detail h2 {
    padding: 0;
    padding-left: 20px;
}

.logo-detail {
    display: flex;
    align-items: start;
    padding: 20px 0;
}

.rate span {
    font-size: 12px;
    color: #cfcfcf;
    padding-bottom: 10px;
    display: block;
}

.rate .star {
    padding-bottom: 10px;
}

.hours h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    margin-left: 20px;
}

.hours span {
    color: #CFCFCF;
    font-weight: 400;
}

.hours {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    border-radius: 20px;
    position: absolute;
    top: 41%;
    z-index: 0;
}

.hours.two {
    top: 60%;
}

.hours i {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    justify-content: center;
    border-radius: 50px;
    color: white;
}

/*tabs*/
.Provides {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent;
    color: #F29F05;
    font-size: 18px;
    font-weight: bold;

}

.nav-link:focus,
.nav-link:hover {
    color: #F29F05;
}

.nav-pills .nav-link.active {
    position: relative;

}

.nav-pills .nav-link.active:before {
    content: "";
    background-color: #f5ab55;
    height: 2px;
    width: 75%;
    position: absolute;
    top: 125%;
}

.nav-link {
    color: #363636;
}

.nav-pills .nav-link {
    font-size: 18px;
    font-weight: bold;
    padding: 0 30px 0 15px;
}

.like-meal a {
    font-size: 18px;
    font-weight: bold;
    color: #F29F05;
    padding-right: 20px;
}

.like-meal i {
    padding-right: 10px;
}

.dish {
    padding: 44px;
    border-radius: 25px;
    margin-top: 40px;
    background-color: white;
}

.dish img {
    padding-bottom: 20px;
}

.dish h3 {
    font-size: 36px;
    padding-bottom: 20px;
    font-weight: bold;
}

.dish .cafa-button a {
    font-size: 14px;
}

.dish-icon .star {
    padding-left: 15px;
}

.dish-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #F2F2F2;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.dish-icon.end {
    border: 0;
}

.dish-icon.end button {
    background-color: transparent;
    border: 0;
}

ul.menu-dish {
    padding-top: 10px;
    font-size: 16px;
    color: #c7c7c7;
}

.menu-dish li {
    position: relative;
    padding-top: 10px;
    padding-left: 20px;
}

header.two {
    position: relative;
    padding: 26px 0;
    height: 100px;
}

.menu-dish li:before {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    background-color: black;
    border-radius: 80px;
    top: 56%;
    LEFT: 0;
}

.dish-info h5 {
    font-size: 24px;
    font-weight: bold;
    width: 80%;
    padding-top: 27px;
}

.dish-info {
    position: relative;
}

.dish-info i {
    position: absolute;
    right: 0%;
    font-size: 18px;
}

/*----------     qty-input      ------------*/
.qty-input {
    color: #000;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    overflow: hidden;
}

.qty-input .product-qty,
.qty-input .qty-count {
    background: transparent;
    color: inherit;
    font-weight: bold;
    font-size: inherit;
    border: none;
    display: inline-block;
    min-width: 0;
    height: 2.5rem;
    line-height: 1;
}

.qty-input .product-qty:focus,
.qty-input .qty-count:focus {
    outline: none;
}

.qty-input .product-qty::-webkit-outer-spin-button,
.qty-input .product-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.qty-input .qty-count {
    cursor: pointer;
    width: 2.5rem;
    font-size: 1.25em;
    text-indent: -100px;
    overflow: hidden;
    position: relative;
    border: 1px solid #F29F05;
    border-radius: 8px;
}

.qty-input .qty-count:before,
.qty-input .qty-count:after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    display: block;
    background: #F29F05;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.qty-input .qty-count--add:after {
    transform: rotate(90deg);
}

.qty-input .qty-count:disabled {
    color: #ccc;
    background: #f2f2f2;
    cursor: not-allowed;
    border-color: transparent;
}

.qty-input .qty-count:disabled:before,
.qty-input .qty-count:disabled:after {
    background: #ccc;
}

.qty-input .qty-count--add {
    color: white;
}

.qty-input {
    border-radius: 4px;
}

.price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}

.price h2 {
    font-weight: bold;
}

.qty-input .product-qty {
    width: 50px;
    min-width: 0;
    display: inline-block;
    text-align: center;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    border: 1px solid #F2F2F2;
    border-radius: 14px;
    font-size: 14px;
    font-weight: 400;
    margin: 0px 10px;
    color: #363636;
}

.dish .button.button-2 {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.button-price {
    color: white;
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #F29F05;
}

.button-price i {
    padding-left: 20px;
}

.button-price:hover {
    background-color: transparent;
    color: #F29F05;

}

/*-------------            Service page        -------------*/
.about-text a {
    margin-top: 30px;
}

.counter-img img {
    border-radius: 30px;
    position: relative;
}

.counter-img-data h2 {
    font-size: 60px;
    font-weight: bold;
    padding-bottom: 10px;
}

.counter-img-data span {
    font-weight: bold;
    font-weight: 18px;
    color: #787878;
}

.counter-img-data {
    background-color: white;
    padding: 35px;
    text-align: center;
    display: block;
    width: 65%;
    border-radius: 30px;
    position: relative;
}

.counter-img-data.black {
    margin: auto;
    background-color: #363636;
}

.counter-img-data.black span {
    color: white;
}

.hero-section-img-service {
    position: relative;
    z-index: 0;
}

.hero-section-img-service:before {
    position: absolute;
    border: #f29f05 7px solid;
    height: 616px;
    width: 500px;
    content: "";
    border-radius: 70px;
    transform: rotate(24deg);
    z-index: 0;
    left: 12%;
}

/*services card*/
.services-info h2 {
    font-weight: bold;
    width: 75%;
    padding-bottom: 25px;
    margin: 0;
}

.services-info p {
    width: 84%;
}

.services-card-style h4 a {
    font-weight: bold;
    font-size: 24px;
    padding-top: 40px;
    padding-bottom: 20px;
    margin: 0;
    display: block;
}

.services-card-style p {
    font-size: 16px;
}

.services-card-style i {
    border-radius: 20px;
    background-color: white;
    padding: 18px;
    font-size: 35px;
    position: relative;
}

.services-card-style i:after {
    position: absolute;
    content: "";
    border: #F29F05 3px solid;
    height: 72px;
    width: 70px;
    border-radius: 20px;
    left: 0;
    top: 0;
    z-index: -1;
    transform: rotate(45deg);
    left: 13%;
}

.services-card-style {
    padding: 40px;
    border: 1px solid transparent;
    border-radius: 60px;
}

.services-card-style:hover {
    border: 1px solid #F29F05;
    border-radius: 10px;
}

.services-card-style:hover i:after {
    border: #363636 3px solid;
}

.services-card-style:hover i {
    color: white;
}

.services-cards-text h2 {
    font-weight: bold;
    width: 80%;
}

.services-cards-text ul.paragraph {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
}

.services-cards-text ul.paragraph li h5 {
    line-height: 26px;
    margin-top: 15px;
}

.services-cards-text ul.paragraph li {
    width: 48%;
    align-items: baseline;
}

.work-card.service {
    padding: 40px;
    box-shadow: 0px 0px 12px 9px rgb(161 151 151 / 15%);
    -webkit-box-shadow: 0px 0px 12px 9px rgb(161 151 151 / 15%);
    -moz-box-shadow: 0px 0px 12px 9px rgb(161 151 151 / 15%);
    margin-top: 40px;
    border-radius: 15px;
    background-color: white;
}

/*sit-at-home*/
.sit-at-img {
    position: relative;
}

.sit-at-img:before {
    border-radius: 40px;
    border: 8px solid #F29F05;
    content: "";
    position: absolute;
    height: 600px;
    width: 600px;
    left: 10%;
    top: 10%;
}

.sit-at-img img {
    border-radius: 40px;
    margin-bottom: 40px;
}

.sit-at-img .counter-img-data {
    bottom: 18%;
    position: absolute;
    width: 30%;
    right: 0;
}

.sit-at-home-description h2 {
    font-weight: bold;
    width: 100%;
}

.sit-at-home-description p {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 25px;
}

ul.food-dishes {
    display: flex;
    flex-wrap: wrap;
}

ul.food-dishes li i {
    padding-right: 10px;
    font-size: 24px;
}

ul.food-dishes li {
    margin-top: 15px;
    padding: 20px;
    margin-right: 18px;
    border-radius: 15px;
}

.sit-at-home-description .button-2 {
    margin-top: 30px;
}

/*-------------------------       lunch-section        --------------- */
.lunch-section h2 {
    font-weight: bold;
    width: 35%;
}

/*-------------------         error-section         -------------------*/
.error-section {
    padding: 150px 0 100px 0;
    text-align: center;
}

.error-page {
    position: relative;
}

.error-page form input[type="text"] {
    outline: 0;
    background-color: white;
    border: 1px solid #cfcfcf;
    width: 50%;
    height: 55px;
    border-radius: 12px;
    padding-left: 20px;
    color: #cfcfcf;
}

.error-page h2 {
    position: absolute;
    background-color: white;
    right: 28%;
    font-size: 80px;
    top: 55%;
    padding: 20px;
    font-weight: bold;
    border-radius: 15px;
}

.error-page form input[type="text"]::placeholder {
    color: #cfcfcf;
}

.error-page form button {
    position: absolute;
    right: 25%;
}

.error-page form {
    position: relative;
}

.error-page img {
    padding: 40px 0;
}

.error-page h4 {
    font-weight: bold;
}

.error-page p {
    width: 60%;
    margin: auto;
}

.error-page a i {
    font-size: 20px;
    color: white;
    padding: 12px;
    border-radius: 10px;
    margin-top: 20px;
}

/*-------------     blog      --------------*/
.hero-section.about.blog-page .about-text h2 {
    color: white;
}

.hero-section.about.blog-page .about-text p {
    color: white;
}

/*-------------     benefit section     --------------*/
.benefit-text h2 {
    font-weight: bold;
    margin: 0;
    padding-bottom: 20px;
}

.name-form input[type="text"]::placeholder {
    color: #cfcfcf;
}

.name-form {
    position: relative;
}

.name-form input[type="text"] {
    color: #cfcfcf;
    border: 1px solid #F2F2F2;
    width: 100%;
    height: 60px;
    padding-left: 46px;
    border-radius: 10px;
    margin-top: 20px;
    outline: none;
}

.name-form i {
    left: 2%;
    position: absolute;
    color: #F2F2F2;
    font-size: 20px;
    top: 50%;
}

.join-courier h3 {
    font-weight: bold;
    padding-bottom: 20px;
    margin: 0;
}

.blog-form textarea::placeholder {
    color: #cfcfcf;
}

.blog-form textarea {
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    border-radius: 20px;
    padding-left: 25px;
    height: 150px;
    color: #cfcfcf;
    border: 1px solid #F2F2F2;
    outline: none;
    margin-bottom: 10px;
}

.Benefit-section {
    position: relative;
}


.Benefit-section .get_services .get_services_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    gap: 16px;
    border-radius: 20px;
}

.Benefit-section .get_services .get_services_content .services_icon_wrapper {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 189, 5, 0.5);
}

.Benefit-section .benefit-text h2 {}

.Benefit-section .get_services .get_services_content .services_icon_wrapper img {
    width: 40px;
    width: 40px;
}

.Benefit-section .get_services .get_services_content .services_text_content p {
    font-size: 16px;
    line-height: 24px;
}

.Benefit-section .get_services .get_services_content .services_text_content h4 {
    margin-bottom: 15px !important;
    font-weight: bold;
}

.join-courier {
    background-color: white;
    padding: 30px;
    border-radius: 30px;
    position: absolute;
    width: 39%;
    top: 32%;
}

.benefit-text {
    margin-top: 41px;
}

.team-description h4 {
    font-weight: bold;
    padding-bottom: 15px;
}

.team-description p {
    font-size: 16px;
}

.team-description {
    border-radius: 20px;
    padding: 40px;
    border: 1px solid #F2F2F2;
}

.team-description i {
    font-size: 30px;
    padding-right: 20px;
}

.team-description {
    height: 100%;
}

.end {
    margin-bottom: 0;
}

/*--------------------       join-img       -------------------------*/
.join-img-blog {
    position: relative;
}

.join-img-blog img {
    border-radius: 20px;
    position: relative;
}

.join-img-blog:before {
    position: absolute;
    content: "";
    border-radius: 20px;
    border: 8px solid #F29F05;
    height: 443px;
    width: 640px;
    left: 5%;
    top: 10%;
}

.join-the h2 {
    font-weight: bold;
    padding-bottom: 20px;
    margin: 0;
}

.counter-blog {
    display: flex;
    margin-top: 40px;
}

.counter-blog .counter-img-data {
    width: 35%;
    margin-left: 25px;
}

.news-posts-one.blog h3 {
    font-size: 24px;
    line-height: 34px;
    padding: 20px 0;
}

/*--------------          faq          -------------*/
.hero-section .join-img-blog img {
    border-radius: 110px;
}

.hero-section .join-img-blog:before {
    border-radius: 110px;
}

.join-img-blog i {
    position: absolute;
    font-size: 50px;
    font-weight: bold;
    color: white;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 9%;
    left: -4%;
    border-radius: 20px;
}

.Provides input[type="text"] {
    border-radius: 15px;
    padding-right: 200px;
    padding-left: 16px;
    color: #cfcfcf;
    border: 1px solid #F2F2F2;
    height: 60px;
    border-radius: 10px;
    outline: none;
}

.Provides input[type="text"]::placeholder {
    color: #cfcfcf;
}

.Provides form {
    position: relative;
}

.Provides form button {
    position: absolute;
    background-color: transparent;
    font-size: 20px;
    outline: none;
    border: none;
    color: #cfcfcf;
    right: 4%;
    top: 27%;
}

/*---------------------        questions        ---------------------*/
.questions i {
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
}

.questions {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    background-color: #FCFCFC;
    padding: 40px 20px;
    border-radius: 15px;
}

.questions h6 {
    font-weight: bold;
    padding-left: 25px;
}

.questions.answer {
    width: 85%;
    padding: 30px;
    margin-left: auto;
    margin-top: 35px;
    margin-bottom: 35px;
    background-color: transparent;
    border: 1px solid #CFCFCF;
}

.questions.answer i {
    background-color: black;
    color: white;
}

.form-section .join-courier {
    position: relative;
    width: 100%;
    top: 0;
}

/*----------         blog page        --------------*/
.restaurants-girl-img.blog.food-photo-section a {
    font-size: 30px;
    height: 60px;
    width: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.restaurants-girl-img.blog.food-photo-section a i {
    padding-right: 0;
    border: 1px solid;
    padding: 9px;
    border-radius: 36px;
    font-size: 22px;
}

.restaurants-girl-img.blog.food-photo-section a.one {
    left: 4%;
    top: 33%;
}

.restaurants-girl-img.blog.food-photo-section a.two {
    left: 8%;
    top: 45%;
}

.restaurants-girl-img.blog.food-photo-section a.three {
    left: 4%;
    top: 57%;
}

.posts-section-blog .news-posts-one.full {
    display: flex;
    align-items: center;
}

.blog-post-data-img img {
    width: auto;
    margin-right: 30px;
}

.blog-post-data-img .quickeat {
    position: absolute;
    top: 11%;
    left: 4%;
}

.posts-section-blog .news-posts-one.blog {
    margin-top: 60px;
    box-shadow: 0px -1px 30px 4px rgb(255 255 255 / 0%);
    -webkit-box-shadow: 0px -1px 30px 4px rgb(255 255 255 / 0%);
    -moz-box-shadow: 0px -1px 30px 4px rgba(255 255 255 / 0%);
    padding: 0;
}

.posts-section-blog .news-posts-one.blog .quickeat {
    position: absolute;
    top: 4%;
    left: 6%;
}

/*-----------------           pricing-table           -----------------*/
.about-text.pricing-table {
    text-align: center;
}

.about-text.pricing-table h2 {
    margin: auto;
}

.about-text.pricing-table p {
    width: 65%;
    margin: auto;
}

.about-text.pricing-table ul.crumbs {
    justify-content: center;
}

/*---------------         pricing-table-cards     ----------*/
.pricing-table-cards {
    position: relative;
    overflow: hidden;
    padding: 30px;
    border-radius: 15px;
}

.pricing-table-cards img {
    position: absolute;
    left: -29%;
    border: 10px solid white;
    border-radius: 97px;
}

.pricing-table-cards h6 {
    font-size: 24px;
    z-index: 11;
    position: relative;
    color: white;
    padding: 12px 25px;
    display: inline-block;
    border-radius: 10px;
    font-weight: bold;
}

.pricing-table-cards i {
    padding-right: 10px;
}

.pricing-table-cards-pric {
    margin-left: 175px;
    margin-top: 20px;
}

.pricing-table-cards-pric h4 {
    font-size: 80px;
    font-weight: bold;
    line-height: 60px;
    margin-top: 35px;
}

.pricing-table-cards-pric h4 span {
    display: block;
    font-size: 18px;
    font-weight: 400;
    color: #787878;
}

.pricing-table-cards-pric h3 {
    font-weight: bold;
    line-height: 54px;
}

.pricing-table-cards p {
    padding-top: 60px;
}

.pricing-plan-list h5 i {
    padding-right: 20px;
}

.pricing-plan-list {
    padding-top: 30px;
    padding-bottom: 20px;
}

.pricing-plan-list h5 {
    font-size: 16px;
    font-weight: 400;
}

li.color h5,
li.color h5 i {
    color: #CFCFCF;
}

/*-----------------        single blog page        -----------------*/
.single-blog .quickeat {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 40px;
}

.single-blog .quickeat a {
    border-radius: 5px;
    color: white;
    padding: 3px 11px;
    font-size: 14px;
}

.single-blog .about-text h2 {
    width: 100%;
    font-size: 60px;
    padding-top: 20px;
}

.single-blog ul.data {
    padding-top: 0px;
    margin-top: 0;
    border: 0;
}

.name-dedails img {
    border-radius: 60px;
}

.name-dedails h6 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding-top: 10px;
}

.name-dedails span {
    color: #787878;
    width: 60%;
    display: block;
}

.img-blog-full img {
    border-radius: 98px;
    margin-top: 40px;
}

.title-container h2,
.title-container h3,
.title-container h4 {
    font-weight: bold;
    padding-bottom: 20px;
}

.quote {
    padding: 80px 0;
}

.quote-text {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

}

.quote h6 {
    text-align: end;
    padding-top: 10px;
    margin: 0;
}

.quote-text h4 {
    padding-left: 30px;
}

.photo-blog {
    padding: 40px 0;
}

.photo-blog img {
    border-radius: 10px;
}

.blog-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 0;
}

.blog-list li {
    width: 50%;
    padding-left: 20px;
    padding-top: 10px;
    position: relative;
}

.blog-list li:before {
    height: 8px;
    width: 8px;
    position: absolute;
    content: "";
    border-radius: 10px;
    left: 0;
    top: 50%;
}

h6.data {
    color: #787878;
    font-size: 14px;
    text-align: end;
    margin: 0;
}

.quickeat.tags {
    position: relative;
    padding: 40px 0;
    left: 0;
    top: 0;
    border-bottom: 1px solid #707070;
}

.quickeat.tags a {
    border-radius: 5px;
    color: white;
    padding: 3px 11px;
    font-size: 14px;
}

/*------------         previous-posts           ---------*/
.previous-posts {
    display: flex;
    align-items: flex-start;
}

.previous-posts img {
    border-radius: 10px;
    padding-right: 20px;
}

.previous-posts h6 {
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
}

.previous-posts a {
    font-size: 14px;
}

.blog-content .name-dedails span {
    width: 100%;
}

.blog-content .name-dedails {
    text-align: center;
}

.name-dedails ul.social-media {
    padding: 20px 0;
    justify-content: center;
}

.previous-posts.next img {
    padding-right: 0;
    padding-left: 20px;

}

.previous-posts.next {
    text-align: end;
}

.author-gap {
    padding: 50px 0;
}

/*--------------------          comment         --------------*/
.comment h2 {
    font-weight: bold;
}

.comment-text {
    padding: 40px;
    background-color: white;
    border-radius: 20px;
    margin-top: 40px;
}

.comment-text span {
    text-align: end;
    display: block;
}

.comment-text span i {
    padding-left: 10px;
}

.author-name-comment img {
    border-radius: 50px;
    margin-left: 20px;
}

.author-name-comment h6 {
    margin: 0;
}

.author-name-comment {
    display: flex;
    padding-top: 20px;
    align-items: center;
    justify-content: end;
    text-align: end;
}

li.reply .author-name-comment img {
    margin-left: 0;
    margin-right: 20px;
}

li.reply .author-name-comment {
    justify-content: start;
    text-align: inherit;
}

li.reply.one {
    width: 95%;
}

li.reply.two {
    width: 90%;
}

li.reply.three {
    width: 85%;
}

li.hide-button,
li.show-all-button {
    padding: 20px 0 0 0;
    text-align: end;
}

li.hide-button h6 i {
    padding-left: 10px;
}

.show-all-button span {
    padding-left: 10px;
}

.show-all-button span i {
    padding-left: 10px;
}

/*------------         comment input         ------------*/
.comment-blog {
    padding-top: 40px;
}

.comment-blog textarea {
    width: 100%;
    height: 160px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #CFCFCF;
    color: #CFCFCF;
    outline: none;
}

.comment-blog textarea::placeholder,
.comment-blog input[type="text"]::placeholder {
    color: #CFCFCF;
}

.comment-blog input[type="text"] {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #CFCFCF;
    color: #CFCFCF;
    outline: none;
    padding-left: 20px;
    margin: 20px 0;
}

/*-------------          contacts   page       ---------------*/
.address {
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
}

.address h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.address h5 {
    font-size: 16px;
    font-weight: 600;
}

.address h5:last-child {
    margin-bottom: 0;
}

.address i {
    font-size: 40px;
    padding-bottom: 22px;
}

.address:hover {
    background-color: #363636;
}

.address:hover h6,
.address:hover span,
.address:hover h5 {
    color: white;
}

.address span {
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
}

.address span:last-child {
    margin-bottom: 0px;
}

.join-courier.content {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    box-shadow: -1px 15px 26px -4px rgb(161 151 151 / 0%);
    -webkit-box-shadow: -1px 15px 26px -4px rgb(161 151 151 / 0%);
    -moz-box-shadow: -1px 15px 26px -4px rgba(161, 151, 151, 0%);
}

.contact-map-data {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0 20px;
}

.contact-map-data iframe {
    border-radius: 15px;
    height: 522px;
}

/*----------------         checkout page          -----------------*/
.title-checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-checkout h6,
.title-checkout h2 {
    font-weight: bold;
    font-size: 48px;
}

.checkout-order .banner-wilmington {
    margin-left: 0;
    padding-top: 40px;
}

.counter-container {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.counter-container h4 {
    font-weight: bold;
    width: 45%;
    padding-left: 20px;
}

.counter-container h3 {
    font-size: 48px;
    font-weight: bold;
}

.counter-food {
    display: flex;
    align-items: center;
}

.price span {
    color: #CFCFCF;
    padding-top: 20px;
    display: block;
}

.price-list .price {
    padding: 0;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.price-list i {
    display: flex;
    justify-content: end;
    font-size: 20px;
    color: #cfcfcf;
}

.totel-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.totel-price span {
    color: #cfcfcf;
    font-size: 18px;
}

.totel-price h5 {
    font-weight: bold;
    font-size: 24px;
}

.totel-price h2 {
    font-weight: bold;
}

.tab-content>.active {
    margin-top: 30px;
}

form.checkout-form button.button-price {
    margin-top: 25px;
}

/*--------------------------               checkout-form            ------------------*/
form.checkout-form h4 {
    font-weight: bold;
}

form.checkout-form h4.two {
    padding-top: 20px;
}

form.checkout-form input[type="text"],
form.checkout-form input[type="number"],
form.checkout-form input[type="password"] {
    width: 100%;
    height: 60px;
    padding-left: 20px;
    border-radius: 10px;
    margin-top: 20px;
    outline: none;
    color: #CFCFCF;
    border: 1px solid #CFCFCF;
}

form.checkout-form input[type="text"]::placeholder,
form.checkout-form input[type="number"]::placeholder,
form.checkout-form input[type="password"]::placeholder {
    color: #CFCFCF;
}

form.checkout-form .nice-select {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    background-color: #FCFCFC;
    color: #CFCFCF;
    border: 1px solid #CFCFCF;
    ;
}

form.checkout-form ul.list {
    width: 100%;
}

form.checkout-form .nice-select:after {
    width: 8px;
    height: 8px;
    right: 30px;
}

form.checkout-form span {
    color: #CFCFCF;
    font-size: 14px;
    display: block;
    padding-top: 10px;
}

form.checkout-form {
    padding: 30px;
    border-radius: 10px;
}

[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* IMAGE STYLES */
[type=radio]+img {
    cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked+img {
    outline: 1px solid #f29f05;
    padding: 12px 18px;
}

.tab-pane label {
    padding-right: 20px;
}

/* The container */
.checkbox-one {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-one input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-one:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-one input:checked~.checkmark {
    background-color: #F29F05;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-one input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-one .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

label.checkbox-one {
    margin-top: 20px;
}

.hero-section.about {
    padding-top: 140px;
}

.hero-section.about.checkout {
    padding: 160px 0 30px;
}

/*-----------------        box shadow        -----------------*/
.logos-card.restaurant-page,
.Provides,
.services-card-style i,
.dish,
.author-text p,
.card-text-data.two,
.get-the-menu input[type="text"],
ul.food-dishes li,
.error-page form input[type="text"],
.join-courier {
    box-shadow: -1px 15px 26px -4px rgb(161 151 151 / 15%);
    -webkit-box-shadow: -1px 15px 26px -4px rgb(161 151 151 / 15%);
    -moz-box-shadow: -1px 15px 26px -4px rgba(161, 151, 151, 0.15);
}

.address,
form.checkout-form,
.counter-blog .counter-img-data,
.contact-map-data,
.pricing-table-cards,
.comment-text {
    box-shadow: 0px 0px 12px 9px rgb(161 151 151 / 15%);
    -webkit-box-shadow: 0px 0px 12px 9px rgb(161 151 151 / 15%);
    -moz-box-shadow: 0px 0px 12px 9px rgb(161 151 151 / 15%);
}

/*-------------     transition      --------------*/
.news-post-two h6 a,
.cafa h4 a,
.cafa-button a,
ul.footer-menu li a,
ul.social-media li a i,
.address span,
.card-text-data,
.card-text-data p,
.card-text-data svg,
.card-text-data h3,
.services-card-style h4 a,
.services-card-style,
.services-card-style i:after,
.services-card-style i,
.button-price,
.address,
.address h6,
.logodata.owl-carousel .owl-item img {
    transition: .3s ease-in-out;
}

/*------------------            menu-inner          ---------------------*/
.menu-inner {
    background-color: white;
    position: fixed;
    right: -45%;
    top: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    transition-delay: 0.4s;
    width: 35%;
    padding: 40px 40px;
    height: 100%;
}

header.style1 .menu-inner .social-links {
    display: inline-block;
    width: 100%;
    text-align: initial;
    opacity: 0;
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    -o-transform: translateX(200px);
    transform: translateX(200px);
    -webkit-transition-delay: 1.05s;
    -moz-transition-delay: 1.05s;
    -o-transition-delay: 1.05s;
    -ms-transition-delay: 1.05s;
    transition-delay: 1.05s;
}

header.style1 .menu-inner .menu-list+.social-links {
    margin-top: 100px;
}

body.active header.style1 .menu-inner .social-links {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

body.active .menu-inner {
    visibility: visible;
    opacity: 1;
    right: 0;
    box-shadow: -50px 1px 25px 2px rgba(168, 168, 168, 0.14);
    -webkit-box-shadow: -50px 1px 25px 2px rgba(168, 168, 168, 0.14);
    -moz-box-shadow: -50px 1px 25px 2px rgba(168, 168, 168, 0.14);
    overflow: auto;
}

body.menu-layer:before {
    content: "";
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    transform: scale(0, 0) translate(50%, -50%);
    -moz-transform: scale(0, 0) translate(50%, -50%);
    -webkit-transform: scale(0, 0) translate(50%, -50%);
    -o-transform: scale(0, 0) translate(50%, -50%);
    -ms-transform: scale(0, 0) translate(50%, -50%);
    -webkit-transition-delay: 0.25s;
    -moz-transition-delay: 0.25s;
    -o-transition-delay: 0.25s;
    -ms-transition-delay: 0.25s;
    transition-delay: 0.25s;
    display: block;
    width: 370vw;
    height: auto;
    padding-top: 370vw;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    border-radius: 50%;
    z-index: 2;
    opacity: .9;
}

.menu-cls-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    right: 40px;
    top: 40px;
    z-index: 9;
}

.cls-leftright {
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 18px;
    background-color: #000;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.cls-rightleft {
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 18px;
    background-color: #000;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.menu-cls-btn:hover .cls-leftright,
.menu-cls-btn:hover .cls-leftright,
.menu-cls-btn:hover .cls-rightleft {
    background-color: #F29F05;
}

.menu-cls-btn:hover .cls-leftright {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.menu-cls-btn:hover .cls-rightleft {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.menu-bar nav>ul>li.menu-item-has-children>a {
    position: relative;
}

.menu-bar nav>ul>li.menu-item-has-children>a:before {
    content: "";
    background-image: url(../img/down-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 53%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    right: 6px;
    width: 10px;
    height: 10px;
}

.menu-inner .price span {
    padding-top: 10px;
}

.menu-inner .counter-container h4 {
    width: 62%;
}

.menu-inner .qty-input {
    border: none;
}

.menu-inner .checkout-order .banner-wilmington {
    margin-left: 0;
    padding-top: 20px;
}

.extras.bag .bar-menu {
    display: none;
}

.header-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .login_user_info .user_image {
    width: 40px;
    height: 40px;
    border-radius: 60px;
    overflow: hidden;
    flex-shrink: 0;
}

header .login_user_info .user_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

header .login_user_info .user_text span {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
}

.login_user_dropdown_menu.ant-dropdown {
    box-shadow: 3px 4px 20px rgba(129, 129, 129, 0.2);
    border-radius: 10px;
    max-width: 260px;
    min-width: 260px !important;
    position: fixed !important;
}

.login_dropdown.ant-dropdown {
    position: fixed !important;
}

.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu {
    padding: 0px;
    font-family: 'Metropolis';
}

.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu-item {
    padding: 16px;
    border-bottom: 1px solid #F2F2F2;
}

.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-item-icon {
    min-width: 20px;
    margin-inline-end: 16px;
    font-size: 20px;
}

.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content a {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
}

.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu-item:last-child {
    border-bottom: none;
}

.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.login_user_dropdown_menu.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.login_user_dropdown_menu.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.login_user_dropdown_menu.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.login_user_dropdown_menu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.login_user_dropdown_menu.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
    background-color: #F2F2F2;
}

/*-------------------       mobile-nav         -----------*/
.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background-color: white;
    padding: 50px 40px 15px;
    z-index: 5;
    transition: 0.45s ease-in-out;
    transform: translateX(-101%);
    -webkit-transform: translateX(-101%);
    -moz-transform: translateX(-101%);
    -o-transform: translateX(-101%);
    overflow-y: auto;
}

.mobile-nav ul li a svg {
    width: 11px;
}

.for #nav-icon4 {
    color: black;
}

#nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
    top: 13px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
    top: 25px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

#nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 35px;
    left: 8px;
}

i#nav-icon4 {
    display: none;
}

#nav-icon4 {
    width: 40px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;
    font-size: 25px;
    color: white;
}

#nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.cart-popup {
    position: absolute;
    right: 130px;
    top: 78px;
    width: 340px;
    background-color: #fff;
    padding: 25px 25px 20px;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -o-transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    box-shadow: 2px 3px 35px 0px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 2px 3px 35px 0px rgb(0 0 0 / 10%);
}

.cart-popup:before {
    content: "";
    width: 41%;
    height: 5px;
    position: absolute;
    left: 59.2%;
    top: -5px;
}

.show-cart {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
}

.cart-popup img {
    width: 75px;
}

.cart-popup .p-img {
    padding: 10px 5px;
}

.cart-popup .p-data h3 {
    font-size: 20px;
    color: #191919;
    margin-bottom: 5px;
}

.cart-popup .p-data {
    padding-left: 15px;
}

.cart-popup .p-data p {
    line-height: 25px;
}

.cart-popup ul li {
    margin-bottom: 10px;
}

.cart-popup ul li a:before {
    content: "";
    width: 25px;
    height: 25px;
    /* background-image: url('../images/remove.html'); */
    background-repeat: no-repeat;
    position: absolute;
    top: -5px;
    left: -10px;
}

.cart-popup .cart-total {
    background-color: #eee;
    padding: 15px;
    margin-bottom: 10px;
}

.cart-popup .cart-total span {
    font-size: 18px;
    line-height: 25px;
    color: #222;
}

.cart-popup .cart-btns a {
    font-size: 16px;
    line-height: 25px;
    color: #222;
    text-transform: uppercase;
}

.cart-popup .cart-btns a.checkout {
    color: white;
}

.cart-popup .cart-btns a {
    font-size: 16px;
    line-height: 25px;
    color: #222;
    text-transform: uppercase;
    width: 50%;
    text-align: center;
    padding: 10px;
    border: 2px solid #909090;
}

.cart-popup .cart-btns a:not(:last-child) {
    margin-right: 8px;
}

.cart-popup .cart-btns a:hover {
    color: white;
}

.mobile-nav>ul>li.menu-item-has-children:before {
    content: "";
    background-image: url(../img/down-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 25px;
    right: 0;
    width: 10px;
    height: 10px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.mobile-nav>ul>li.menu-item-has-children.active:before {
    background-image: url(../img/down-arrow-clr.svg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.mobile-nav>ul>li {
    position: relative;
    border-bottom: 1px solid #d4d4d4;
    padding: 10px 0;
    display: block;
}

.mobile-nav>ul li>a {
    font-size: 16px;
    line-height: 26px;
}

.mobile-nav>ul>li>a {
    font-size: 16px;
    line-height: 30px;
}

.mobile-nav>ul>li.menu-item-has-children>ul.sub-menu {
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 5px;
    position: absolute;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(15px);
    -webkit-transform: translateX(15px);
    -moz-transform: translateX(15px);
    -o-transform: translateX(15px);
    border-top: 1px solid #d4d4d4;
    margin-top: 8px;
}

.mobile-nav>ul>li.menu-item-has-children.active>ul.sub-menu {
    position: relative;
    height: auto;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transition-delay: 0.1s;
}

.mobile-nav>ul>li.menu-item-has-children.active>ul.sub-menu li {
    padding: 5px 0;
    position: relative;
}

.res-log img {
    width: auto;
}

.mobile-nav>ul>li.menu-item-has-children.active>ul.sub-menu li:before {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: -13px;
}

.res-log {
    margin-bottom: 30px;
}

.mobile-nav .donation {
    display: flex;
    align-items: center;
    margin: 25px 0;
}

.mobile-nav .donation a:first-child {
    margin-right: 40px;
}

.mobile-nav .donation a.theme-btn {
    padding: 10px 30px;
}

.mobile-nav .donation a.cart {
    position: relative;
}

.mobile-nav .donation a.cart:before {
    content: "1";
    color: white;
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    top: -7px;
    right: -19px;
    z-index: 1;
    font-size: 13px;
}

.res-rights p {
    color: #d6d6d6;
    font-weight: bold;
    letter-spacing: 5px;
    margin-top: 30px;
}

.mobile-nav.open {
    transform: translateX(0) !important;
    z-index: 3333;
    box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
}

li.menu-item-has-children.active {
    background-color: transparent;
}

.mobile-nav a#res-cross:before {
    content: "";
    width: 35px;
    height: 35px;
    background-image: url('../img/remove.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 15px;
    right: 20px;
}

.bar-menu i {
    color: black;
    background-color: transparent;
    font-size: 16px;
}

/*-------------------         loader         -------------------*/
.wrapper {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    left: 15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0%;
    }
}

.circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.shadow {
    width: 15px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: .7;
    }

    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
}

.shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.wrapper span {
    position: absolute;
    top: 75px;
    font-size: 20px;
    letter-spacing: 12px;
    color: #fff;
    left: 15%;
}

.page-loader {
    z-index: 1111111111111;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
}

/* App download */
/* .button_download{
    text-align: center;
    color: #000;
    text-transform: uppercase;
    display: inline-block;
    font-size: 16px;
    padding: 0 25px;
    border-radius: 10px;
    height: 68px;
    line-height: 64px;
}
.button_download:hover{
    background-position: 120%;
    background-color: transparent;
} */
.app_download_btn {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 12px;
    text-align: left;
}

.app_download_btn i {
    font-size: 36px;
}

.app_download_btn .download_text {
    width: 100%;
    padding-left: 0px;
}

.app_download_btn p span {
    font-size: 20px;
    font-weight: 600;
}

.app_download_btn p {
    line-height: 1.2;
    font-size: 13px;
    font-weight: 600;
    color: #363636;
    text-transform: capitalize;
}

.app_journey_slider .slick-track {
    padding-top: 60px;
    padding-bottom: 60px;
}

.app_journey_slider .slide_item {
    padding: 0px 12px;
}

.app_journey_slider .slider_image img {
    transform: scale(0.95);
    transition: all 0.4s ease;
}

.app_journey_slider .slick-current.slick-center .slider_image img {
    transform: scale(1.1);
}

/* .app_journey_slider .slick-dots li button{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    border-radius: 50%;
    outline: none;
    background-color: black;
} */
/* .app_journey_slider .slick-dots .slick-active button{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 19px;
    height: 5px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    border-radius: 3px;
    outline: none;
    background-color: #F29F05;
    transition: all 0.3s ;
} */
/* .app_journey_slider .slick-dots li button:active{
       background-color: #F29F05;
} */

.app_journey_slider .slick-dots,
.customers_testmonials .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
    bottom: 0px;
}

.customers_testmonials .slick-dots {
    bottom: -30px;
}

.app_journey_slider .slick-dots li,
.customers_testmonials .slick-dots li {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #727272;
    display: block;
    padding: 0px;
    margin: 0px;
    transition: all 0.5s ease;
}

.app_journey_slider .slick-dots li button:before,
.customers_testmonials .slick-dots li button:before {
    display: none;
}

.app_journey_slider .slick-dots li.slick-active,
.customers_testmonials .slick-dots li.slick-active {
    width: 54px;
    background-color: #f29f05;
}

.customers_testmonials .slick-dots li.slick-active {
    width: 30px;
}

.app_journey_slider .slick-dots li button,
.customers_testmonials .slick-dots li button {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.Benefit-section .benefit-text {
    margin: 0px 0px 20px 0px !important;
}

.benefits_last_section {
    width: 100%;
    background-color: #f29f0514;
    display: flex;
    align-items: center;
    justify-content: center;
}

.benefits_last_section.gap {
    padding: 60px 0px !important;
}

.benefits_last_section .individual-content {
    padding: 0px 60px;
}

.benefits_last_section .individual-content p {
    color: #000000;
    font-weight: 500;
}

.benefits_last_section .individual-content .icon_wrapper {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 0px auto;
    border: 2px solid rgba(255, 189, 5, 0.5);
    margin-bottom: 16px;
}

.benefits_last_section .individual-content .icon_wrapper img {
    width: 40px;
    height: 40px;
}


.content-area:not(:last-child) {
    margin-bottom: 30px;
}

.content-area p {
    font-size: 14px !important;
    font-weight: 500;
    margin-bottom: 14px;
}

.content-area ul {
    margin: 20px 0px 20px 50px;
}

.content-area ul li {
    margin-bottom: 12px;
    display: list-item;
    list-style: disc;
    font-weight: 600;
    font-size: 14px;
}

.content-area ul li a,
.content-area p a {
    color: #3030F1 !important;
    font-size: 14px !important;
}

.content-area ul li strong {
    font-weight: 600 !important;
}

.content-area table {
    margin: 30px 0px;
    border: 1px solid #ababab;
}

.content-area table td {
    border-right: 1px solid #ababab;
}

.content-area .content_wrapper_div {
    padding: 15px;
    border: 1px solid #acacac;
    margin-bottom: 16px;
}

.content-area .content_wrapper_div ul {
    margin: 0px 0px 0px 20px !important;
}

.content-area .content_wrapper_div ul li {
    margin-bottom: 8px !important;
    list-style: none;
}

.content-area .content_wrapper_div ul li p {
    color: #787878;
}

.flex_item_cc,
.flex_item_cb,
.flex_item_ca,
.flex_item_cs,
.flex_item_ce,
.flex_item_ss,
.flex_item_sb,
.flex_item_sc,
.flex_item_se {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    gap: 16px;
}

.flex_item_cc {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex_item_cb {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex_item_ca {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.flex_item_cs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.flex_item_ce {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.flex_item_ss {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.flex_item_sb {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: space-between;
}

.flex_item_sc {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex_item_se {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

/* input box */
.form-group .form-control {
    border: 1px solid #BDBDBD;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    padding: 19px 24px;
}

.form-group .form-control:focus {
    outline: none;
    box-shadow: none;
}

/* Input With Icons */

.left-input-icon,
.right-input-icon,
.link-text-group {
    position: relative;
}

.right-input-icon .form-control {
    padding-right: 45px;
}

.left-input-icon .form-control {
    padding-left: 45px;
}

.right-input-icon .right-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #C8C8C8;
}

.left-input-icon .left-icon {
    position: absolute;
    top: 8px;
    left: 15px;
    color: #C8C8C8;
}

.left-input-icon :where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px;
    height: auto;
    border: none;
}

.left-input-icon :where(.css-dev-only-do-not-override-1km3mtt).ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0px;
    right: 0px;
}

.left-input-icon :where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    box-shadow: none;
}

/* normal css */

.bg_images {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg_lightgray {
    background-color: rgba(242, 242, 242, 0.3);
}

.bg_white {
    background-color: #ffffff !important;
}

.maxw-300 {
    max-width: 300px;
    width: 100%;
}

.fs12 {
    font-size: 12px !important;
}

.fs14 {
    font-size: 14px !important;
}

.fs16 {
    font-size: 16px !important;
}

.fs18 {
    font-size: 18px !important;
}

.fs20 {
    font-size: 20px !important;
}

.fs22 {
    font-size: 22px !important;
}

.fs24 {
    font-size: 24px !important;
}

.fs26 {
    font-size: 26px !important;
}

.fs28 {
    font-size: 28px !important;
}

.fs30 {
    font-size: 30px !important;
}

.fs32 {
    font-size: 32px !important;
}

.fs34 {
    font-size: 34px !important;
}

.fs36 {
    font-size: 36px !important;
}

.fs38 {
    font-size: 38px !important;
}

.fs40 {
    font-size: 40px !important;
}

.fs48 {
    font-size: 48px !important;
}


.fw400 {
    font-weight: 400 !important;
}

.fw500 {
    font-weight: 500 !important;
}

.fw600 {
    font-weight: 600 !important;
}

.fw700 {
    font-weight: 700 !important;
}

.lh_1 {
    line-height: 1 !important;
}

.lh_1_3 {
    line-height: 1.3;
}

.lh_1_4 {
    line-height: 1.4;
}

.lh_1_5 {
    line-height: 1.5;
}

.text_orange {
    color: #FFBD05 !important;
}

.text_green {
    color: #27AE60 !important;
}

.text_blue {
    color: #253D83 !important;
}

.text_dark1 {
    color: #333230 !important;
}

.text_c818181 {
    color: #818181 !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb14 {
    margin-bottom: 14px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.text_1line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text_2line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text_3line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.section_title {
    margin-bottom: 50px;
}

.section_title h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
}

.slide_gap30 .slick-track {
    margin-left: -15px;
    margin-right: -15px;
}

.slide_gap30 .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    background-color: #e0e6f0;
    border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #e0e6f0;
    border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #b4bbcb;
    border-radius: 8px;
}

.custom_dots .slick-dots {
    bottom: 0px;
}

.custom_dots .slick-dots li {
    width: auto;
    height: auto;
    margin: 0px;
    margin-left: 8px;
}

.custom_dots .slick-dots li:first-child {
    margin-left: 0px;
}

.custom_dots .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #F2F2F2;
    padding: 0px;
    transition: all 0.4s ease;
}

.custom_dots .slick-dots li.slick-active button {
    width: 36px;
    background-color: #FFBD05;
}

.custom_dots .slick-dots li button:before {
    display: none;
}

.custom_dots .slick-list {
    padding-bottom: 42px;
}

/* order header */
header.order_header_wrapper {
    padding: 10px 0px !important;
}

.order_header_wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #ffffff;
    z-index: 999;
    box-shadow: 0px 4px 6px rgb(51 48 48 / 8%);
}

.order_header_wrapper .header_flex_main .action_right_part button.bar-menu,
.order_header_wrapper .extras.bag button.bar-menu {
    background-color: transparent;
    border: none;
    padding: 0px;
}

.order_header_wrapper .mobile-nav.hmburger-menu ul li .button {
    width: 100%;
    padding: 8px 24px;
}

.header_flex_main {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 40px;
    position: relative;
}

.gap30 {
    gap: 20px;
}

.gap30 {
    gap: 28px;
}


header .search_filter_box .responsive_close_icon {
    position: absolute;
    top: -15px;
    right: -5px;
    cursor: pointer;
}

header .search_filter_box,
.header_exact_bottom .search_filter_box {
    background: #F2F2F2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 6px 16px;
    max-width: 650px;
    width: 100%;
    gap: 14px;
    position: relative;
}

header .search_filter_box.max_400 {
    max-width: 400px !important;
}

.header_exact_bottom .search_filter_box {
    max-width: 100%;
}

header .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper,
header .search_filter_box .search_box .location_input .ant-input-affix-wrapper,
.header_exact_bottom .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper,
.header_exact_bottom .search_filter_box .search_box .location_input .ant-input-affix-wrapper {
    padding: 8px 0px !important;
    border: none !important;
    font-family: inherit !important;
}

header .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper,
header .search_filter_box .search_box .location_input .ant-input-affix-wrapper,
.header_exact_bottom .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper,
.header_exact_bottom .search_filter_box .search_box .location_input .ant-input-affix-wrapper {
    padding: 8px 0px !important;
    border: none !important;
}

header .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
header .search_filter_box .search_box .location_input .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.header_exact_bottom .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.header_exact_bottom .search_filter_box .search_box .location_input .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    box-shadow: unset !important;
}

header .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper .ant-input-prefix,
header .search_filter_box .search_box .location_input .ant-input-affix-wrapper .ant-input-prefix,
.header_exact_bottom .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper .ant-input-prefix,
.header_exact_bottom .search_filter_box .search_box .location_input .ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 10px !important;
}

header .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper .ant-input,
header .search_filter_box .search_box .location_input .ant-input-affix-wrapper .ant-input,
.header_exact_bottom .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper .ant-input,
.header_exact_bottom .search_filter_box .search_box .location_input .ant-input-affix-wrapper .ant-input {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: inherit !important;
    color: #000 !important;
    letter-spacing: unset !important;
}

header .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper .ant-input::placeholder,
header .search_filter_box .search_location_filter .search_box .location_input .ant-input-affix-wrapper .ant-input::placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: inherit !important;
    color: #000 !important;
    letter-spacing: unset !important;
}

header .search_filter_box .search_box .location_input .ant-input-affix-wrapper .ant-input::placeholder,
.header_exact_bottom .search_filter_box .search_box .location_input .ant-input-affix-wrapper .ant-input::placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: inherit !important;
    color: #818181 !important;
    letter-spacing: unset !important;
}

header .search_filter_box .search_location_filter .search_box .ant-select .ant-select-selector,
header .search_filter_box .search_box .ant-select .ant-select-selector,
.header_exact_bottom .search_filter_box .search_location_filter .search_box .ant-select .ant-select-selector,
.header_exact_bottom .search_filter_box .search_box .ant-select .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
}

header .search_filter_box .search_box .MuiFormControl-root,
.header_exact_bottom .search_filter_box .search_box .MuiFormControl-root {
    margin-top: 0px !important;
}

header .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input,
.header_exact_bottom .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
    padding: 8px 28px !important;
}

header .search_filter_box .search_box .icon_start_wide_left,
.header_exact_bottom .search_filter_box .search_box .icon_start_wide_left {
    position: absolute;
    top: 2px;
    left: -6px;
}

header .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input::placeholder,
.header_exact_bottom .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input::placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: inherit !important;
    color: #000 !important;
    letter-spacing: unset !important;
}

header .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root,
.header_exact_bottom .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: inherit !important;
    color: #000 !important;
    letter-spacing: unset !important;
}

header .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.header_exact_bottom .search_filter_box .search_box .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

header .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.header_exact_bottom .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    padding: 0px 14px 0px 26px;
}

header .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
.header_exact_bottom .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    padding: 0px 14px 0px 16px;
}

header .search_filter_box .ant-select-focused:where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector,
.header_exact_bottom .search_filter_box .ant-select-focused:where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    box-shadow: none;
    outline: none;
}

header .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select-single .ant-select-selector,
.header_exact_bottom .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select-single .ant-select-selector {
    font-family: 'Metropolis';
}

header .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select,
.header_exact_bottom .search_filter_box :where(.css-dev-only-do-not-override-1km3mtt).ant-select {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #333230;
}

header .search_filter_box .search_box,
.header_exact_bottom .search_filter_box .search_box {
    position: relative;
}

header .search_filter_box .search_box .icon,
.header_exact_bottom .search_filter_box .search_box .icon {
    position: absolute;
    top: 0px;
    left: 0px;
}

.search_filter_box .search_location_filter {
    width: 100%;
    padding-right: 10px;
}

.search_filter_box {
    width: calc(100% - 264px);
}

.order_header_wrapper .action_right_part ul.extras li.search-location-toogle-icon {
    display: none;
}

.order_header_wrapper .action_right_part ul.extras li a.nav-link {
    position: relative;
}

.order_header_wrapper .action_right_part ul.extras li a.nav-link svg,
.order_header_wrapper .action_right_part ul.extras li a.nav-link svg path {
    fill: #f29f05 !important;
}

.order_header_wrapper .action_right_part ul.extras li a.nav-link img.nav_icon {
    width: 20px;
    height: 20px;
}

.order_header_wrapper .action_right_part ul.extras li.active a.nav-link {
    color: #f29f05 !important;
}

.order_header_wrapper .action_right_part ul.extras li.active a.nav-link {
    color: #f29f05 !important;
}

.order_header_wrapper .action_right_part ul.extras li a.login_dropdown {
    cursor: pointer;
}

.order_header_wrapper .action_right_part ul.extras li a.login_dropdown .ant-space {
    gap: 4px !important;
}

.order_header_wrapper .action_right_part ul.extras li a.login_dropdown .ant-space .ant-space-item {
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1;
}

.order_header_wrapper .action_right_part ul.extras li a.login_dropdown .ant-space .ant-space-item:last-child {
    font-size: 14px !important;
}

.order_header_wrapper .action_right_part ul.extras li a.nav-link::after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -8px;
    background-color: transparent;
    left: 0px;
}

.order_header_wrapper .action_right_part ul.extras li.active a.nav-link::after {
    background-color: #f29f05;
}

.order_ctg_filter_slider a {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.order_ctg_filter_wrapper a .ctg_icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 155, 5, 0.1);
}

.order_ctg_filter_wrapper a .ctg_icon img {
    width: 48px;
    height: 48px;
}

.offers_slider_main .offers_card {
    padding: 30px;
    border-radius: 20px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: overlay;
}

.offers_slider_main .offers_card .text_part {
    width: 60%;
    flex-grow: 1;
}

.offers_slider_main .offers_card .text_part p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333230;
    margin-bottom: 30px;
}

.offers_slider_main .offers_card .text_part .button {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    color: #000000;
    border-radius: 8px;
    text-transform: capitalize;
    height: auto;
}

.offers_slider_main .offers_card .image_part {
    width: 40%;
    flex-shrink: 0;
}


.products_cards {
    padding: 20px;
    background-color: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 20px;
    margin-bottom: 24px;
}

.products_cards .products_card_text .left_part p svg {
    flex-shrink: 0;
}

.products_card_text h5,
.products_card_text p:not(:last-child) {
    margin-bottom: 14px;
}

.products_card_text h5 a {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #333230;
    display: block;
}


.products_card_text .left_part {
    flex-grow: 1;
}

.products_card_text .right_part {
    flex-shrink: 0;
}

.products_card_text p {
    font-weight: 400;
    font-size: 14px;
    color: #818181;
    line-height: 1.5;
    white-space: normal;
    word-break: break-word;
}

.products_card_text .rating_badge {
    background-color: #27AE60;
    border-radius: 6px;
    padding: 7px 10px;
}

.products_card_text .rating_badge p {
    color: #ffffff;
    opacity: 1;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
}

.wishlist_icon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.products_card_image {
    background-color: #D9D9D9;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    height: 275px;
}

.products_card_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product_action .wishlist_icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.slider_flex .slick-track {
    display: flex !important;
}

.slider_flex .slick-track .slick-slide {
    height: inherit !important;
}

.slider_flex .slick-track .slick-slide>div,
.slider_flex .slick-track .slick-slide .offers_slider_item {
    height: 100% !important;
}

.custom_container {}

.custom_container .slick-dots {
    left: calc(-50% + 660px);
}

/* .custom_container .offers_slider_main .slick-slider .slick-list{
    overflow: unset;
} */
/* .custom_container .offers_slider_main .slick-slider .slick-list .slick-track{
    padding-right: 221px;
} */

.foods_menu_categories .ctg_item a {
    border-radius: 8px;
    padding: 4px 14px;
    gap: 14px;
}

.foods_menu_categories .ctg_item .ctg_icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
}

.foods_menu_categories .ctg_item .ctg_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.foods_menu_categories .ctg_item.active a {
    background-color: #FFBD05;
}

.shop_detials_banner .shop_detials_banner_main .banner {
    width: 100%;
    height: 260px;
    border-radius: 25px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* .shop_detials_banner .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 200px;
} */

.shop_detials_banner .shop_detials_banner_main .shop_text h1 {
    max-width: 500px;
}

.shop_detials_banner .shop_logo {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 9px rgb(0 0 0 / 3%);
    width: 100px;
    height: 100px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;
    left: 68px;
}

.food_type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}

.food_type {
    color: #333230;
}

.food_type .dots {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    display: block;
    background-color: #333230;
}

.rating_star_part span {
    display: block;
    line-height: 1;
    color: #ffbd05;
    font-weight: 600;
    font-size: 22px;
    line-height: 18px;
}

.rating_star_part img {
    width: 24px;
}

.shops_details_section .custom_col_right .menu_items_card {
    border-left: 3px solid #f2f2f2;
    margin-left: 6px;
    padding-left: 30px;
}

.shops_details_section .menu_items_card .menu_items_list {
    max-height: 680px;
    overflow-y: auto;
    padding-right: 14px;
}

.product_list_main li {
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 20px;
    padding-top: 20px;
}

.product_list_main li:first-child {
    padding-top: 0px;
}

.product_list_main .product_list_item .product_image {
    width: 148px;
    height: 148px;
    border-radius: 20px;
    overflow: hidden;
}

.product_list_main .product_list_item .product_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product_list_main .product_list_item .product_text h4 {
    line-height: 1;
    margin-bottom: 18px;
}

.product_list_main .product_list_item .product_text h4 a {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #333230;
}

.product_list_main .product_list_item .product_text .price del {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #EB5757;
}

.product_list_main .product_list_item .product_text .price {
    justify-content: flex-start;
    gap: 4px;
    align-items: center;
    padding: 0px;
    margin-bottom: 18px;
    line-height: 1;
}

.product_list_main .product_list_item .product_text .price ins {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    color: #27AE60;
    text-decoration: none;
}

.product_list_main .product_list_item .product_text .food_type {
    margin-bottom: 18px;
    line-height: 1;
    text-transform: capitalize;
}

.shops_details_section .menu_footer {
    background-color: #253D83;
    padding: 12px 30px;
}

.shops_details_section .menu_footer h6 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
}

.shops_details_section .menu_footer h6 span {
    display: block;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    margin-top: 8px;
}

.shops_details_section .menu_footer .button.button-2 {
    padding: 10px 28px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #333230;
    height: auto;
    text-transform: capitalize;
}

.shops_details_section .menu_footer .button.button-2:hover {
    color: #FFBD05;
}

.shop_menu_category ul li:not(:last-child) {
    margin-bottom: 16px;
}

.shop_menu_category ul li a {
    background-color: #F2F2F2;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #333230;
    display: block;
    padding: 16px;
}

.shop_menu_category ul li a.active {
    background-color: #FFBD05;
}

.qty_button {
    background-color: #FFBD05;
    border-radius: 8px;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    max-width: 140px;
}

.qty_button .qty_input,
.qty_button .qty_input:hover {
    width: 100%;
    color: #333230;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    border: none;
    text-align: center;
    background-color: transparent;
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -ms-progress-appearance: none !important;
}

.qty_button .qty_input::-webkit-outer-spin-button,
.qty_button .qty_input::-webkit-inner-spin-button {
    display: none;
}

.qty_button .qty_input:focus {
    outline: none;
}

.qty_button .btn_qty {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar_wrapper .sidebar_widget {
    padding: 30px;
    background-color: rgba(242, 242, 242, 0.5);
    border-radius: 20px;
    margin-bottom: 20px;
}

.sidebar_wrapper .sidebar_widget:last-child {
    margin-bottom: 0px;
}

.custom_radio_group {
    position: relative;
    padding-left: 26px;
    margin-bottom: 20px;
}

.custom_radio_group:last-child {
    margin-bottom: 0px;
}

.custom_radio_group .radio_input[type="radio"]:checked,
.custom_radio_group .radio_input[type="radio"]:not(:checked) {
    position: absolute;
    left: 0px;
    opacity: 0px;
    width: 0px;
}

.custom_radio_group .radio_input[type="radio"]:checked+.radio_label,
.custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label {
    background: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    line-height: 1;
    display: block;
    padding: 15px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #818181;
    border: 1px solid #FFFFFF;
}

.payment_card_box {
    cursor: pointer;
}

.custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label p {
    color: #818181;
}

.custom_radio_group .radio_input[type="radio"]:checked+.radio_label:before,
.custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #818181;
    border-radius: 100%;
    background: transparent;
    transform: translateY(-50%);
}

.custom_radio_group .radio_input[type="radio"]:checked+.radio_label:after,
.custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #FFBD05;
    position: absolute;
    top: calc(50% - 4px);
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
    transform: translateY(-50%);
}

.custom_radio_group .radio_input[type="radio"]:checked+.radio_label,
.custom_radio_group .radio_input[type="radio"]:checked+.radio_label:before {
    border-color: #FFBD05;
}

.payment_method_card .custom_radio_group .radio_input[type="radio"]+.radio_label {
    padding: 9px 10px;
}

.payment_method_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label p,
.custom_radio_group.style1 .radio_input[type="radio"]:checked+.radio_label p {
    color: #333230;
}

.custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.custom_radio_group .radio_input[type="radio"]:checked+.radio_label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.custom_radio_group.style1 .radio_input[type="radio"]:checked+.radio_label,
.custom_radio_group.style1 .radio_input[type="radio"]:not(:checked)+.radio_label {
    padding: 0px;
    border: none;
}


.billtotal_table_wrapper tbody tr th,
.billtotal_table_wrapper tbody tr td {
    border: none;
    font-weight: 500;
    line-height: 1;
    vertical-align: middle;
    padding: 0px 0px 20px;
}

.billtotal_table_wrapper tbody tr th {
    font-size: 14px;
}

.billtotal_table_wrapper tbody tr td {
    font-size: 16px;
    text-align: right;
}

.billtotal_table_wrapper tfoot tr td,
.billtotal_table_wrapper tfoot tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    padding: 20px 0px 0px;
    text-align: left;
    border-bottom: none;
    border-top: 1px solid #818181;
}

.billtotal_table_wrapper tfoot tr td {
    text-align: right;
    color: #27AE60;
}

.sidebar_widget .button.button-2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: #333230;
    height: auto;
    padding: 15px 14px;
    border-radius: 8px;
    text-transform: capitalize;
}

/* profile */

.profile_card {
    border: 1px solid rgba(129, 129, 129, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    padding: 50px;
}

.profile_image_card {
    border-radius: 10px;
    width: 192px;
    height: 192px;
    overflow: hidden;
    position: relative;
}

.profile_image_card .edit_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #FFFFFF;
}

.profile_image_card .edit_btn a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.manage_address_card {
    background: rgba(242, 242, 242, 0.5);
    border-radius: 30px;
    padding: 20px;
}

.manage_address_card .custom_radio_wrap .custom_radio_group .address_box .address_box_action {
    position: absolute;
    right: 15px;
    top: 15px;
}

.manage_address_card .custom_radio_group {
    padding-left: 44px;
}

.manage_address_card .custom_radio_group .radio_input[type="radio"]+.radio_label h6 {
    color: #818181;
}

.manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label h6,
.manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label p {
    color: #333230;
}

.manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label a svg path {
    stroke: #333230;
}

.manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label h6 svg path {
    stroke: #FFBD05;
}

.manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label:before,
.manage_address_card .custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label:before {
    width: 24px;
    height: 24px;
}

.manage_address_card .custom_radio_group .radio_input[type="radio"]:checked+.radio_label:after,
.manage_address_card .custom_radio_group .radio_input[type="radio"]:not(:checked)+.radio_label:after {
    width: 12px;
    height: 12px;
    top: calc(50% - 6px);
    left: 6px;
}

.order_history_page .product_list_main .product_list_item .product_image {
    width: 154px;
    height: 154px;
}

.order_history_tabs {
    overflow-x: auto;
}

.order_history_tabs ul li a {
    padding: 14px 30px;
    background: #F2F2F2;
    border-radius: 8px;
    color: #818181;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: block;
}

.order_history_tabs ul li a.active {
    background-color: #FFBD05;
    color: #000000;
    font-weight: 600;
}

.past_order_list .product_list_main li {
    border: 1px solid rgba(129, 129, 129, 0.3);
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 16px;
}

.cart_list_drawer .product_list_main li:last-child {
    margin-bottom: 0px;
    border-bottom: none;
    padding-bottom: 0px;
}

.past_order_list .product_list_main .product_list_item .product_image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
}

.cart_list_drawer .product_list_main .product_list_item .product_image {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}

.cart_list_drawer .product_list_main .product_list_item .product_text h4 {
    font-size: inherit;
    line-height: 1;
    margin-bottom: 8px;
}

.cart_list_drawer .product_list_main .product_list_item .product_text .price {
    margin-bottom: 8px;
}

.cart_list_drawer .product_list_main .product_list_item .product_text h4 a {
    font-size: 16px;
}

.cart_list_drawer .product_list_main .product_list_item .product_text .price ins {
    font-size: 18px;
    line-height: 1;
}

.cart_list_drawer .product_list_main .product_list_item .product_text .price del {
    font-size: 14px;
    line-height: 1;
}

.cart_list_drawer .product_list_main .product_list_item .product_text .food_type {
    gap: 8px !important;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 8px;
}

.cart_list_drawer .product_list_main .product_list_item .product_text .qty_button {
    padding: 6px 10px;
    gap: 4px;
    max-width: 80px;
}

.cart_list_drawer .product_list_main .product_list_item .product_text .qty_button .btn_qty {
    width: 16px;
    height: 16px;
}

.cart_drawer_total .billtotal_table_wrapper tbody tr th,
.cart_drawer_total .billtotal_table_wrapper tbody tr td {
    padding-bottom: 8px !important;
}

.cart_drawer_total .billtotal_table_wrapper tfoot tr td,
.cart_drawer_total .billtotal_table_wrapper tfoot tr th {
    padding-top: 14px !important;
}

.custom_drawer_menu.ant-drawer .ant-drawer-footer {
    padding: 24px;
}

.custom_drawer_menu .ant-drawer-header-title {
    flex-direction: row-reverse;
}

:where(.css-dev-only-do-not-override-1km3mtt)[class^="ant-drawer"],
:where(.css-dev-only-do-not-override-1km3mtt)[class*=" ant-drawer"],
:where(.css-dev-only-do-not-override-1km3mtt).ant-select-dropdown,
:where(.css-dev-only-do-not-override-1km3mtt).ant-dropdown,
:where(.css-dev-only-do-not-override-1km3mtt).ant-badge .ant-scroll-number,
:where(.css-dev-only-do-not-override-1km3mtt).ant-select-single .ant-select-selector,
:where(.css-dev-only-do-not-override-1me4733)[class^="ant-form"],
:where(.css-dev-only-do-not-override-1me4733)[class*=" ant-form"],
:where(.css-dev-only-do-not-override-1me4733)[class^="ant-input"],
:where(.css-dev-only-do-not-override-1me4733)[class*=" ant-input"],
:where(.css-dev-only-do-not-override-1me4733).ant-input,
.ant-steps,
.ant-steps .ant-steps-item-icon,
.ant-collapse {
    font-family: 'Metropolis';
}

.action_right_part :where(.css-dev-only-do-not-override-1km3mtt).ant-badge .ant-scroll-number {
    font-size: 10px;
    padding: 0 4px;
    line-height: 16px;
    height: 16px;
}

.notification_item_card {
    margin-bottom: 16px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 16px;
    cursor: pointer;
}

.notification_lists .notification_item_card .text {
    opacity: 0.5;
}

.notification_lists .notification_item_card .text.unread {
    opacity: 1;
}

.notification_lists .notification_item_card:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}

.notification_item_card .icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: #f2f2f2;
    flex-shrink: 0;
}

.notification_item_card h6 {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 4px;
}

.notification_item_card h6 a {
    font-size: inherit;
    font-weight: inherit;
    display: block;
}

.notification_item_card p {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 0px;
}

.custom_drawer_menu .button.button-2 {
    height: auto;
    line-height: 1;
    padding: 14px 16px;
    font-size: 16px;
    font-weight: 500;
}

/* event  */
.events_happening_near_you .section_heading,
.popular_events .section_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.events_happening_near_you .section_heading h2,
.popular_events .section_heading h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    color: #000000;
}

.events_happening_near_you .section_heading a,
.popular_events .section_heading a {
    font-size: 18px;
    text-decoration: underline;
    font-weight: 500;
}

.individual_card {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 20px;
    padding: 20px;
}

.individual_card .img_wrapper,
.individual_card .img_wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

/* .individual_card .img_wrapper a {
    height: 100%;
}

.individual_card .img_wrapper a {
    width: 100%;
    display: block;
} */

.individual_card .img_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.individual_card .img_wrapper .event_date_absolute {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #fff;
    padding: 10px 14px;
    border-radius: 10px;
    text-align: center;
}

.individual_card .img_wrapper .event_date_absolute span {
    display: block;
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.individual_card .img_wrapper .event_date_absolute span.numeric_value,
.individual_card .img_wrapper .event_date_absolute span.numeric_value {
    font-size: 20px;
    font-weight: 500;
}

.individual_card .text-content {
    margin-top: 14px;
    display: flex;
    align-items: start;
}

.individual_card .text-content .main_text {
    flex-grow: 1;
}

.individual_card h4.title {
    font-size: 20px;
    font-weight: 500;
    color: #333230 !important;
    line-height: 1.5 !important;
}

.individual_card p.subtext {
    font-size: 16px;
    font-weight: 500;
    color: #818181;
    line-height: 1.3;
}

.individual_card .rating_badge {
    padding: 6px 12px;
    border-radius: 6px;
    background-color: #27AE60;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    line-height: 1.3;
}

.individual_card .rating_badge .icon {
    margin-top: -4px;
}

.individual_card .rating_badge .icon svg {
    width: 16px;
    height: 16px;
}

.individual_card .text-content .event_price_numaric {
    flex-shrink: 0;
}

.individual_card .text-content .main_text p span:first-child {
    white-space: nowrap;
}

.individual_card .text-content .main_text p span svg {
    flex-shrink: 0;
}

.individual_card .text-content .event_price_numaric {
    padding: 4px 14px;
    background-color: #F2F2F2;
    border-radius: 8px;
}

.individual_card .text-content .event_price_numaric span {
    color: #27AE60;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.individual_card .text-content .event_price_numaric span.unvalide_price {
    margin-right: 4px;
    color: red;
    font-size: 14px;
    font-weight: 400;
}


/*event details */
.event_details_hero_section {
    height: auto;
    background-color: #f2f2f280;
    padding: 30px 0px;
}

.event_details_hero_section .banner_wrapper {
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
}

.event_details_hero_section .banner_wrapper .event_date_absolute {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    padding: 12px 16px;
    border-radius: 10px;
    text-align: center;
}

.event_details_hero_section .banner_wrapper .event_date_absolute span {
    display: block;
    line-height: 1;
    font-size: 15px;
    font-weight: 500;
}

.event_details_hero_section .banner_wrapper .event_date_absolute span.numeric_value {
    font-size: 21px;
    font-weight: 500;
}

.event_details_hero_section .text_content_bottom {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.event_details_hero_section .text_content_bottom>div {
    flex-grow: 1;
}

.event_details_hero_section .text_content_bottom h3 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}

.event_details_hero_section .text_content_bottom p {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
}

.event_details_hero_section .text_content_bottom p span {
    display: flex;
    align-items: center;
    gap: 8px;
}

.event_details_hero_section .text_content_bottom .price_numaric span {
    display: flex;
    align-items: center;
    color: #27AE60;
    font-size: 28px;
    font-weight: 500;
}

.event_details_hero_section .text_content_bottom .price_numaric span.unvalide_price {
    margin-right: 4px;
    color: red;
    font-size: 22px;
    font-weight: 400;
}

.event_details_hero_section .text_content_bottom .price_numaric span p {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.event_details_about .text_paragraph_wrapper h4 {
    font-weight: 600;
    color: #000000;
}

.event_details_about .text_paragraph_wrapper p {
    font-size: 16px;
    font-weight: 400;
    color: #818181;
    margin-bottom: 16px;
}

.event_details_about .form_wrapper {
    /* background-color: #f2f2f280;
    height:auto;
    border-radius: 20px;
    padding: 30px; */
}

.event_details_about .form_wrapper .details_form {}

.event_details_about .form_wrapper .details_form ul li {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}


.event_details_about .form_wrapper .details_form ul li:last-child {
    margin-bottom: 0px !important;
}

.event_details_about .form_wrapper .details_form ul li label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
    color: #000000cc;
}

.event_details_about .form_wrapper .details_form ul li input {
    padding: 10px 20px;
    background: #FFFFFF;
    border: 1px solid #9c9a9a;
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #333230;
    text-transform: capitalize;
}

.event_details_about .form_wrapper .details_form ul li .input-control {
    padding: 14px 20px;
}

.event_details_about .form_wrapper .details_form ul li .react-tel-input input.form-control {
    width: 100% !important;
    padding: 12.5px 14px 12.5px 48px !important;
    font-size: 14px;
    line-height: initial;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    background-color: transparent;
    outline: none;
    height: unset !important;
}

.event_details_about .form_wrapper .details_form ul li .react-tel-input input.form-control:focus {
    box-shadow: unset !important;
    background-color: unset !important;
}

.event_details_about .form_wrapper .details_form ul li .react-tel-input .flag-dropdown ul.country-list li {
    display: block !important;
    margin-bottom: 0px;
}

.event_details_about .form_wrapper .details_form ul.charges_details li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.event_details_about .form_wrapper .details_form ul.charges_details li .title {
    width: calc(50%);
    font-size: 14px;
    font-weight: 400;
    color: #333230;
}

.event_details_about .form_wrapper .details_form ul.charges_details li .text {
    width: calc(50% - 20px);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: #333230;
}

.event_details_about .form_wrapper .details_form ul.charges_details li:last-child .title,
.event_details_about .form_wrapper .details_form ul.charges_details li:last-child .text {
    font-weight: 600;
    font-size: 16px;
}

.event_details_about .form_wrapper .details_form ul.charges_details li:last-child .text {
    color: #27AE60;
}

.event_details_about .form_wrapper .details_form button {
    min-width: 100%;
    margin-top: 50px;
}

/* //card section componetn */
.card_section_wrapper {
    width: 100%;
    height: auto;
}

.card_section_wrapper .middle_content {
    width: 100%;
    height: auto;
    padding: 30px;
    background-color: #f2f2f280;
    border-radius: 20px;
    margin-bottom: 30px;
}

.card_section_wrapper .middle_content h6 {
    font-weight: 600;
    margin-bottom: 24px;
}

.card_section_wrapper .middle_content .card_radio_select ul li {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.card_section_wrapper .middle_content .card_radio_select ul li input {
    opacity: 1;
    height: unset;
    width: unset;
    position: unset !important;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid #C7C7C7;
    float: none;
    margin: -1px 0px 0px;
    flex-shrink: 0;
}

.card_section_wrapper .middle_content .card_radio_select ul li label {
    background-color: #ffffff;
    padding: 10px 12px;
    flex-grow: 1;
    border: 1px solid #FFBD05;
    border-radius: 8px;
}

.card_section_wrapper .middle_content .card_radio_select ul li label div {
    display: flex;
    align-items: start;
    gap: 12px;
}

.card_section_wrapper .middle_content .card_radio_select ul li label div .image_wrapper {
    width: 55px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 3px;
    overflow: hidden;
}

.card_section_wrapper .middle_content .card_radio_select ul li label div .image_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.card_section_wrapper .middle_content .card_radio_select ul li label div p {
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
}

.card_section_wrapper .middle_content .card_radio_select ul li:last-child {
    margin-left: 36px;
}

.card_section_wrapper .middle_content .card_radio_select ul li button.add_cards_btn {
    background-color: #ffffff;
    padding: 10px 12px;
    flex-grow: 1;
    border: 1px solid #FFBD05;
    border-radius: 8px;
    min-width: 100%;
}

.card_section_wrapper .middle_content .card_radio_select ul li button.add_cards_btn div {
    display: flex;
    align-items: center;
    gap: 12px;
}

.card_section_wrapper .middle_content .card_radio_select ul li button.add_cards_btn div .image_wrapper_bg_gray {
    width: 55px;
    height: 35px;
    flex-shrink: 0;
    background-color: #F2F2F2;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_section_wrapper .middle_content .card_radio_select ul li button.add_cards_btn div p {
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
}

.card_section_wrapper button {
    min-width: 100%;
}

.custom_slider_wrapper .individual_slide_linear_bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    padding: 120px;
    overflow: hidden;
    border-radius: 25px;
}

.custom_slider_wrapper {
    position: relative;
}

.custom_slider_wrapper .individual_slide_linear_bg .text_content {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.custom_slider_wrapper .individual_slide_linear_bg .text_content .right_text h4 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 14px
}

.custom_slider_wrapper .individual_slide_linear_bg .text_content .right_text p,
.custom_slider_wrapper .individual_slide_linear_bg .text_content .right_text p span {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    line-height: 1;
}

.custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text {
    padding: 14px 20px;
    background-color: #27AE60 !important;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 15px;
}

.custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span {
    font-size: 32px;
    font-weight: 500;
    color: white;
}

.custom_slider_wrapper .individual_slide_linear_bg .text_content p.left_text span:first-child {
    font-size: 24px;
    color: white;
    font-weight: 400;
}

.custom_nav_arrows button.custom_nav_arrow_left {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background: #F2F2F2;
    box-shadow: 0px 4px 22px rgba(140, 140, 140, 0.5);
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.custom_nav_arrows button.custom_nav_arrow_right {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background: #f2f2f2;
    box-shadow: 0px 4px 22px rgba(140, 140, 140, 0.5);
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.custom_nav_arrows button.custom_nav_arrow_left,
.custom_nav_arrows button.custom_nav_arrow_right {
    transition: all 0.3s ease;
}

.custom_nav_arrows button.custom_nav_arrow_left:hover,
.custom_nav_arrows button.custom_nav_arrow_right:hover {
    background-color: #FFBD05;
}


/* //charity */
.charity_section_wrapper .charity_cards_wrapper .individual_card {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 20px;
    padding: 20px;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .img_wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .img_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .img_wrapper .charity_date_absolute {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #fff;
    padding: 10px 14px;
    border-radius: 10px;
    text-align: center;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .img_wrapper .charity_date_absolute span {
    display: block;
    line-height: 1;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .img_wrapper .charity_date_absolute span.numeric_value {
    font-size: 21px;
    font-weight: 500;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .text_content {
    margin-top: 14px;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .text_content h4 {
    display: block;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .text_content div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .text_content div span {
    font-size: 24px;
    font-weight: 600;
    color: #000;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .text_content div p {
    font-size: 20px;
    font-weight: 400;
}

.charity_section_wrapper .charity_cards_wrapper .individual_card .ant-progress-bg,
.charity-details_hero_section .text_content_bottom .ant-progress-bg {
    background-color: #FFBD05;
}

/* charity-details */
.charity-details_hero_section {
    height: auto;
    background-color: #f2f2f280;
    padding: 20px 0px;
}

.charity-details_hero_section .banner_wrapper {
    width: 100%;
    height: 300px;
    background-image: url(../images/event-details-background.png);
    background-position: center;
    background-size: cover;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
}

.charity-details_hero_section .banner_wrapper .charity_date_absolute {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    padding: 12px 16px;
    border-radius: 10px;
    text-align: center;
}

.charity-details_hero_section .banner_wrapper .charity_date_absolute span {
    display: block;
    line-height: 1;
    font-size: 15px;
    font-weight: 500;
}

.charity-details_hero_section .banner_wrapper .charity_date_absolute span.numeric_value {
    font-size: 21px;
    font-weight: 500;
}

.charity-details_hero_section .text_content_bottom {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.charity-details_hero_section .text_content_bottom h3 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}

.charity-details_hero_section .text_content_bottom p {
    margin-bottom: 10px;
}

.charity-details_hero_section .text_content_bottom>div .date_percent_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.charity-details_hero_section .text_content_bottom>div .date_percent_between p,
.charity-details_hero_section .text_content_bottom>div .date_percent_between span {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.charity_details_about .text_paragraph_wrapper h4 {
    font-weight: 600;
    color: #000000;
}

.charity_details_about .text_paragraph_wrapper p {
    font-size: 16px;
    font-weight: 400;
    color: #818181;
    margin-bottom: 16px;
}

.charity_details_about .form_wrapper {
    background-color: #f2f2f280;
    height: auto;
    border-radius: 20px;
    padding: 30px;
}

.charity_details_about .form_wrapper form ul li {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.charity_details_about .form_wrapper form ul li label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
    color: #000000;
}

.charity_details_about .form_wrapper form ul li input {
    padding: 10px 20px;
    background: #FFFFFF;
    border: 1px solid #9c9a9a;
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #333230;
    text-transform: capitalize;
}

.charity_details_about .form_wrapper form button[type="submit"] {
    min-width: 100%;
    margin-top: 20px;
}

/* add new card modal */
.add_card_modal_wrapper {
    max-width: 450px !important;
    width: 95% !important;
    height: auto;
}

.add_card_modal_wrapper .ant-modal-content {
    width: 100% !important;
    height: 100% !important;
    padding: 40px;
    border-radius: 30px;
}

.ant-modal-content .ant-modal-close {
    top: 40px;
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.ant-modal-content .ant-modal-close:hover {
    background-color: #F29F05;
    color: #fff;
}

.ant-modal-content .ant-modal-close .ant-modal-close-x {
    line-height: 0;
}

.ant-modal-content .ant-modal-body .card_content h5.starting_heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 20px;
}

.ant-modal-content .ant-modal-body .card_content form {
    width: 100%;
}

.ant-modal-content .ant-modal-body .card_content form ul li {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.ant-modal-content .ant-modal-body .card_content form ul li .input_width50_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 14px;
}

.ant-modal-content .ant-modal-body .card_content form ul label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #333230;
    line-height: 16px;
}

.ant-modal-content .ant-modal-body .card_content form ul input {
    padding: 10px 20px;
    background: #FFFFFF;
    border: 1px solid #333230;
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #333230;
    width: 100%;
    text-transform: capitalize;
    color: #333230;
}

.add_card_modal_wrapper .ant-modal-content .ant-modal-body .card_content form button {
    min-width: 100%;
    margin-top: 10px;
}

.ant-modal-content .ant-modal-body .card_content button {
    min-width: 100%;
    margin-top: 10px;
}

:where(.css-dev-only-do-not-override-1km3mtt).ant-modal {
    font-family: inherit !important;
}

:where(.css-dev-only-do-not-override-1km3mtt)[class^="ant-modal"] {
    font-family: inherit !important;
}

:where(.css-dev-only-do-not-override-1km3mtt).ant-modal .ant-modal-body,
:where(.css-dev-only-do-not-override-1km3mtt).ant-modal {
    line-height: unset;
}

/* payment sucessfull modal */
.payment_successful_modal_wrapper,
.payment_failed_modal_wrapper {
    max-width: 560px !important;
    width: 95% !important;
    height: auto;
}

.payment_successful_modal_wrapper .ant-modal-content,
.payment_failed_modal_wrapper .ant-modal-content {
    width: 100%;
    height: 100%;
    padding: 40px;
    border-radius: 30px;
}

.ant-modal-content .card_content .circular_image_wrapper {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0px auto;
    margin-bottom: 40px;
}

.ant-modal-content .card_content .payment_status_title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1;
}

.ant-modal-content .card_content .payment_status_discription {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
}

.ant-modal-content .card_content button {
    margin-top: 30px !important;
}

.payment_successful_modal_wrapper .ant-modal-content .sidebar_wrapper .sidebar_widget,
.payment_failed_modal_wrapper .ant-modal-content .sidebar_wrapper .sidebar_widget {
    border-radius: 8px;
}

/* feedback modal */
.feedback_modal_wrapper,
.thanks_for_feedback_modal_wrapper {
    max-width: 500px !important;
    width: 95% !important;
    height: auto;
}

.feedback_modal_wrapper .ant-modal-content {
    width: 100% !important;
    height: 100% !important;
    border-radius: 12px;
    padding: 80px 70px 30px 70px;
    position: relative;
}

.ant-modal-content .ant-modal-body .card_content h5.starting_heading_center_32 {
    text-align: center;
    font-size: 26px;
    line-height: 26px;
    color: #333230;
    font-weight: 600;
    margin-bottom: 30px;
}

.ant-modal-content .ant-modal-body .card_content p.sub_heading {
    font-size: 18px;
    line-height: 22px;
    color: #333230;
    text-align: center;
    white-space: nowrap;
}

.feedback_modal_wrapper .ant-modal-content .ant-modal-close,
.thanks_for_feedback_modal_wrapper .ant-modal-content .ant-modal-close {
    display: none;
}

.ant-modal-content .absolute_icon_wrapper {
    width: 130px;
    height: 130px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.ant-modal-content .absolute_icon_wrapper img {
    width: 80px;
    height: 80px;
}

.feedback_modal_wrapper .ant-modal-content .rating_star {
    justify-content: space-between;
    margin-top: 25px;
}

.feedback_modal_wrapper .ant-modal-content .ant-rate,
.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .ant-rate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    font-size: 42px;
    line-height: 1;
}

.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .ant-rate+span,
.cart_page_wrapper .ant-rate+span {
    margin-bottom: -5px;
    color: #253D83;
    font-size: 22px;
}

.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .ant-rate {
    font-size: 18px !important;
    gap: 6px !important;
    justify-content: start !important;
}

.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .ant-rate li,
.cart_page_wrapper .ant-rate li {
    border-bottom: unset !important;
}

.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .ant-rate .ant-rate-star,
.cart_page_wrapper .ant-rate .ant-rate-star {
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1;
}

.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .ant-rate .ant-rate-star:first-child {
    padding-bottom: 0px;
}

.feedback_modal_wrapper .ant-modal-content .ant-rate .ant-rate-star,
.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .ant-rate .ant-rate-star {
    margin-inline-end: 0px !important;
}

.feedback_modal_wrapper .ant-modal-content .rating_star img {
    width: 50px;
    height: 50px;
}

.feedback_modal_wrapper .ant-modal-content button {
    margin-top: 40px !important;
}

/* thanks for feedback modal */
.thanks_for_feedback_modal_wrapper {
    max-width: 550px !important;
    width: 95% !important;
    height: auto;
}

.thanks_for_feedback_modal_wrapper .ant-modal-content {
    width: 100% !important;
    height: 100% !important;
    border-radius: 12px;
    padding: 70px 30px 30px 30px;
    position: relative;
}

.thanks_for_feedback_modal_wrapper .ant-modal-content button {
    min-width: 80% !important;
    margin: auto;
    display: block;
    min-width: unset;
}

/* home poge bottom slider */
/* .custom_slider_wrapper {
    width: 100%;
    height: 300px;
    position: relative;
}

.custom_slider_wrapper .single_slide_slider_wrapper {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    overflow: hidden;
}



.custom_slider_wrapper .single_slide_slider_wrapper .slick-slider,
.custom_slider_wrapper .single_slide_slider_wrapper .slick-slider .slick-list,
.custom_slider_wrapper .single_slide_slider_wrapper .slick-slider .slick-list .slick-track,
.custom_slider_wrapper .single_slide_slider_wrapper .slick-slider .slick-list .slick-track .slick-slide,
.custom_slider_wrapper {
    height: 100%;
}

.custom_slider_wrapper {
    width: 100%;
}

 */
/*---------------login-page---------------*/
.page-ath-wrap {
    display: flex;
    min-height: 100vh
}

.page-ath-right {
    flex-direction: row;
}

.page-ath-left {
    flex-direction: row-reverse;
}

.page-ath-logo {
    display: inline-block;
    width: 100%;
    margin-bottom: 45px;
    text-align: center;
}

.page-ath-logo img {
    max-width: 160px;
}

.page-ath-form .auth-head {
    text-align: center;
    margin-bottom: 25px;
    display: inline-block;
    width: 100%;
}

.page-ath-form .auth-head h6 {
    font-size: 24px;
    font-weight: 600;
    color: #0e1528;
}

.page-ath-form .auth-head p {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}


.page-ath-form {
    max-width: 490px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
}

.page-ath-header {
    padding-top: 40px
}

.page-ath-footer {
    padding-bottom: 30px
}

.page-ath-content {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
}

.page-ath-gfx {
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    display: flex;
    align-items: center;
    width: 60%;
}

.forgot-link a {
    color: #121212;
    font-weight: 600;
    letter-spacing: 0.3px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e4e6e9;
    opacity: 1;
}

.mt-btn {
    margin-top: 35px;
}

.form-footer-link p {
    color: #0e1528;
    font-size: 13px;
    letter-spacing: 0.3px;
    font-weight: 500;
}

.form-footer-link p a {
    color: #F29F05;
    font-size: 14px !important;
}

.forgot-link a {
    letter-spacing: 0.3px;
    font-size: 14px;
    color: #F29F05;
    font-weight: 500;
}

.login-btn {
    background-color: #00ae86;
    color: #fff;
    display: inline-block;
    padding: 12px 32px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    transition: 0.5s;
    text-align: center;
    text-transform: capitalize;
}

.code-group {
    position: relative;
}

.code-group .form-control {
    padding-right: 90px;
}

.code-sent-info {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #000000;
    font-weight: 500;
    cursor: pointer;
}

.code-sent-info img.icon {
    width: 16px;
    height: 16px;
}

.code-sent-info .small-info {
    margin-right: 5px;
}

.info-tooltip {
    font-size: 14px;
    position: relative;
    top: -1px;
    vertical-align: middle;
}

.code-link {
    background-color: transparent;
    border: none;
    position: absolute;
    white-space: nowrap;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0px 15px;
    line-height: 50px;
    letter-spacing: 0.3px;
    font-size: 12px;
    color: #0c61f7;
    font-weight: 500;
}

.form-group .label-name {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 2px;
    font-family: 'Metropolis' !important;
}

/*-----login-center-----*/
.page-ath-center {
    position: relative;
}

.page-ath-center .page-ath-gfx {
    position: absolute;
    width: 100%;
    height: 100%;
}

.page-ath-center .page-ath-content {
    z-index: 1;
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    align-items: center;
}

.page-ath-center .page-ath-form {
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 30px;
    width: 95%;
}


.input-control,
.select-control-custom .ant-select .ant-select-selector {
    padding: 12px 14px;
    font-size: 14px;
    line-height: initial;
    border: 1px solid #dadbdb;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    background-color: transparent;
    outline: none;
}

.select-control-custom .ant-select .ant-select-selector {
    padding: 6.5px 22px !important;
    transition: unset !important;
}

.select-control-custom .ant-select .ant-select-selector .ant-select-selection-item {
    transition: unset !important;
}

.input-control::placeholder,
.input-control .ant-input::placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #000000e6;
}

.ant-input,
.ant-input-affix-wrapper {
    transition: none;
    background-color: transparent;
}

.input-control:focus,
.input-control.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: #0c61f7 !important;
}

.input-control:hover,
.input-control.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #e9f0f7;
}

.input-control.ant-input-password {
    padding: 0;
}

.input-control.ant-input-password .ant-input {
    padding: 13px 14px;
    line-height: initial;
    border-radius: 8px;
}

.input-control.ant-input-password .ant-input-suffix {
    margin-left: 4px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-control-custom .ant-select .ant-select-selector {
    height: unset !important;
    font-family: 'Metropolis' !important;
}

.select-control-custom .ant-select .ant-select-selector .ant-select-selection-search {
    display: none;
}

/* .select-control .input-control .ant-select-selector{
     width: 100%;
     height: unset !important;
     border: none !important;
     line-height: 1 !important;
     background-color: transparent !important;
     font-family: 'Metropolis' !important;
     padding: unset !important;
     position: unset !important;
     transition: unset !important;
     border-radius: unset !important;
     font-size: unset !important;
} */
.select-control .input-control .ant-select-selector .ant-select-selection-search input[type="search"].ant-select-selection-search-input {
    display: none;
    height: inherit !important;
}

/* input-form-group */
.input-form-group .input-control {
    padding: 12px 16px;
    padding-left: 22px;
}

.input-form-group .ant-input-suffix {
    margin-left: 5px;
    padding-left: 10px;
    border-left: 1px solid rgb(14 21 40 / 10%);
}

.input-form-group .ant-input-prefix {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgb(14 21 40 / 10%);
}

.input-form-group .input-control.input-sm {
    padding: 10px 20px;
}

.input-form-group .input-control.input-sm.ant-input-affix-wrapper>input.ant-input {
    font-size: 13px;
    position: relative;
    top: 1px;
}

.page-ath-content .page-ath-form .ant-form .form-group .ant-form-item,
:where(.css-dev-only-do-not-override-1me4733).ant-form-item {
    margin-bottom: 0px !important;
}

/* otp verification */
.otp-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.otp-container .input-control {
    padding: 0;
    text-align: center;
    width: 48px;
    min-width: 48px;
    height: 48px;
    color: #000;
}

.code-timer {
    display: block;
    font-size: 14px;
    color: #00000080;
    font-weight: 500;
    margin-top: 8px;
}

.banner-content {
    text-align: center;
}

.market-page-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.market-page-header .page-title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
}

.search-input-group {
    position: relative;
    min-width: 300px;
}

.search-input-group .input-control {
    padding: 0;
    overflow: hidden;
}

.search-input-group .input-control .ant-input {
    padding: 11px 16px;
    padding-left: 10px;
}

.search-input-group .ant-input-prefix {
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    font-size: 14px;
}

.page-ath-form .button {
    font-size: 16px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

:where(.css-dev-only-do-not-override-1me4733).ant-form-item .ant-form-item-explain-error {
    font-size: 13px;
    margin-top: 4px;
}

/* //remove cart modal */

/* //confirm cart modal */
.confirm-cart-modal .modal-header .close-modal-btn,
.remove-cart-modal .modal-header .close-modal-btn {
    border: none;
    background-color: transparent;
    outline: none;
}

.confirm-cart-modal,
.remove-cart-modal {
    max-width: 450px;
    width: 100% !important;
}

.confirm-cart-modal .ant-modal-content,
.remove-cart-modal .ant-modal-content {
    border-radius: 10px !important;
}

.confirm-cart-modal button.small-height,
.remove-cart-modal button.small-height {
    padding: 12px 24px !important;
}

.ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 0px !important;
}

:where(.css-dev-only-do-not-override-1me4733).ant-form-item .ant-form-item-explain-error {
    font-size: 13px;
    margin-top: 4px;
}

.form-group .react-tel-input input.form-control {
    width: 100% !important;
    padding: 10.5px 14px 10.5px 48px !important;
    font-size: 14px;
    line-height: initial;
    border: 1px solid #dadbdb;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    background-color: transparent;
    outline: none;
    height: unset !important;
}

.product_list_main .product_list_item .product_text .left_part .ant-ribbon-wrapper .ant-ribbon,
.sidebar_wrapper .sidebar_widget .ant-ribbon-wrapper .ant-ribbon {
    position: unset !important;
}

.event-details-sidebar .img_100px_wrapper,
.donation_details_sidebar .img_100px_wrapper,
.order_details_page .past_order_list .product_list_item .product_card .img_100px_wrapper {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
}

.event-details-sidebar .event_image_wrapper img,
.donation_details_sidebar .img_100px_wrapper img,
.event-details-sidebar .img_100px_wrapper img,
.order_details_page .past_order_list .product_list_item .product_card .img_100px_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.cart_page_wrapper .empty_cart_design {
    max-width: 320px;
    margin: auto;
    margin-top: 120px;
}

.cart_page_wrapper .empty_cart_design .button {
    font-size: 15px;
}

/* //empty card concept end */
.profile_page_wrapper .address_form .select_location_from_group .customized_before .form-control.ant-input-affix-wrapper::before {
    content: "" !important;
}

.profile_page_wrapper .address_form .select_location_from_group .customized_before .form-control.ant-input-affix-wrapper .ant-input {
    line-height: 1 !important;
}

/* ant notification amending start */
.ant-notification .ant-notification-notice {
    display: flex;
    align-items: center;
}

.ant-notification .ant-notification-notice .ant-notification-notice-content {
    flex-grow: 1;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
    flex-shrink: 0;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close:hover {
    background-color: unset !important;
}

.ant-notification .ant-notification-notice .ant-notification-notice-message {
    font-size: 14px !important;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
    position: unset !important;
}

.ant-notification .ant-notification-notice.ant-notification-notice-success .ant-notification-notice-icon {
    color: #57c95c !important;
}

.ant-notification .ant-notification-notice.ant-notification-notice-error .ant-notification-notice-icon {
    color: #d32f2f !important;
}

.login-tabs .ant-tabs-nav:before {
    display: none;
}

.login-tabs .ant-tabs-ink-bar {
    display: none;
}

.login-tabs .ant-tabs-tab {
    font-size: 14px;
    color: #6e778b;
    font-weight: 600;
    font-family: "Metropolis";
    padding: 9px 26px;
    border-radius: 8px;
}

.login-tabs .ant-tabs-tab:hover {
    color: #0e1528 !important;
}

.login-tabs .ant-tabs-nav {
    margin-bottom: 25px;
}

.login-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffbd05;
}

.login-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0e1528;
    text-shadow: none;
}

.login-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 16px;
}

.login-tabs .ant-tabs-nav-wrap {
    justify-content: center;
}

.product_list_main .product_list_item .right_part.rating_content .ant-rate {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
}

.product_list_main .product_list_item .right_part.rating_content .ant-rate .ant-rate-star {
    margin: 0px !important;
    padding: 0px !important;
    border: none !important;
    background: none !important;
}

.data_fetch_loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: hwb(0deg 21.18% 78.82% / 60%);
    z-index: 999;
}

.data_fetch_loader .ant-spin .ant-spin-dot-item {
    background-color: #FFBD05 !important;
}

/* .footer_has_bgimage{
    background-image:url('../img/footer_bg.png');
    background-color: #F2F2F2;
    background-blend-mode: overlay;
} */


.events_details_cards_wrapper .slick-slider.slide_gap30 .slick-track {
    margin-left: 0px !important;
}

.events_details_cards_wrapper .slick-slider.slide_gap30 .slick-slide {
    padding-right: 0px !important;
}

.order_details_page .past_order_list .product_list_item .product_card {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 10px;
}

.order_details_page .past_order_list .product_list_item .product_card h6 {
    padding: 6px 8px;
    border-radius: 5px;
    background-color: #ffbd05;
}

.order_history_page .product_list_main .product_list_item .product_text .right_part a {
    cursor: pointer;
    position: relative;
    color: #253D83 !important;
    font-weight: 600 !important;
}

/* .order_history_page .product_list_main .product_list_item .product_text .right_part a::before{
  content:'';
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: -3px;
} */
.shops_details_section .menu_items_card .menu_items_list ul.product_list_main .product_list_item .product_text .right_part .button {
    padding: 12px 20px;
}

.cookies_banner_wrapper {
    max-width: 450px;
    min-height: 250px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    position: fixed;
    bottom: 50px;
    left: 50px;
    z-index: 999;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.cookies_banner_wrapper .cookies_content {
    position: relative;
}

.cookies_banner_wrapper .cookies_content .buttons_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
}

.cookies_banner_wrapper .cookies_content .buttons_wrapper .button_outside {
    width: calc(50% - 9px);
}

.cookies_banner_wrapper .cookies_content .buttons_wrapper .button_outside .button {
    width: 100%;
    font-size: 14px;
}

.cookies_banner_wrapper .cookies_content .buttons_wrapper .button_outside:first-child .button {
    background-image: unset !important;
    background: transparent;
}

.cookies_banner_wrapper .cookies_content .cookies_gif_absolute {
    position: absolute;
    top: -68px;
    right: -54px;
}

.cookies_banner_wrapper .cookies_content .cookies_gif_absolute img {
    width: 80px;
    height: 80px;
}

.header_flex_main .menu_part_wrapper {
    flex-grow: 1;
}

.header_flex_main .menu_part_wrapper .restaurants_search_filter {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 100%;
    padding: 10px;
    background: #ffffff !important;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    display: none;
    border: 1px solid #d9d9d9;
}

.header_flex_main .menu_part_wrapper .restaurants_search_filter .search_filter_box {
    max-width: unset !important;
    padding: 2px 16px;
    position: relative;
}

.header_flex_main .menu_part_wrapper .location_search_filter .search_filter_box .responsive_close_icon,
.header_flex_main .menu_part_wrapper .restaurants_search_filter .search_filter_box .responsive_close_icon {
    position: absolute;
    top: -15px;
    right: -3px;
}

/* .header_flex_main .menu_part_wrapper .location_search_filter .search_filter_box .responsive_close_icon{
    display: none;
} */

/* new design css */
.maxw_60 {
    max-width: 60%;
}

.landing_section {
    min-height: 700px;
    background-image: url("../images/newdesign/landing_page_bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: fixed;
}

.search_item_form.maxw_60 {
    max-width: 60%;
    width: 100%;
    margin: auto;
}

.search_item_form .form-group .form-control {
    display: flex;
    background-color: #ffffff;
    border: none;
    outline: none;
    padding: 4px 4px 4px 24px;
    border-radius: 16px;
}

.search_item_form .form-group .form-control:focus,
.search_item_form .form-group .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
}

.search_item_form .form-group .form-control.ant-input-affix-wrapper .ant-input-suffix button {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom_steps .ant-steps .ant-steps-item .ant-steps-item-icon {
    width: 64px;
    height: 64px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #F2F2F2;
    border: none !important;
}

.custom_steps .ant-steps .ant-steps-item .ant-steps-item-container:hover .ant-steps-item-icon {
    border: none !important;
}


.custom_steps .ant-steps .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
    color: #818181;
    font-size: 18px;
    font-weight: 500;
}


.custom_steps .ant-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon,
.custom_steps .ant-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.custom_steps .ant-steps .ant-steps-item .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.custom_steps .ant-steps .ant-steps-item .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
    color: #000000 !important;
}

.custom_steps .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
    margin-inline-start: 72px !important;
    padding: 4px 32px !important;
}

.custom_steps .ant-steps .ant-steps-item-tail {
    top: 27px;
}

.custom_steps .ant-steps .ant-steps-item-tail::after {
    background-color: rgba(5, 5, 5, 0.1) !important;
}

.custom_steps .ant-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-icon {
    background-color: #FFBD05;
    box-shadow: rgba(17, 17, 26, 0.075) 0px 0px 16px;
}

.custom_steps .ant-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
}

.custom_steps .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail::after {
    background-color: #FFBD05 !important;
    height: 1px;
}

.custom_steps .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #ffffff;
    box-shadow: rgba(17, 17, 26, 0.075) 0px 0px 16px;
}

.custom_steps .ant-steps .ant-steps-item .ant-steps-item-content {
    width: 148px;
}

.custom_steps .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps-item-title {
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
}

.custom_steps .ant-steps .ant-steps-item.ant-steps-item-finish .ant-steps-icon .anticon-check svg {
    fill: #FFBD05;
    width: 18px;
    height: 18px;
}

.img_content_resp img {
    max-width: 100%;
    margin: auto;
}

.partner_with_us {
    background-color: #f2f2f266;
}

.faq_section .faq_content_wrapper {
    background: rgba(242, 242, 242, 0.5);
    border-radius: 30px;
    padding: 50px;
    overflow: auto;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse {
    background-color: transparent !important;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 18px 0px !important;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
    font-size: 15px;
    font-weight: 600;
    color: #333230;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    padding: 0px 0px 16px 0px;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box p {
    font-size: 14px;
    font-weight: 400;
    color: #818181;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item:first-child {
    border-top: 2px solid #8282824d;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item {
    border-bottom: 2px solid #8282824d;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item .ant-collapse-expand-icon {
    margin-inline-end: 0px !important;
    padding-inline-end: 0px !important;
    width: 22px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq_section .faq_content_wrapper .questions_wrapper .ant-collapse .ant-collapse-item .ant-collapse-expand-icon {
    width: 22px !important;
    height: 22px !important;
}


/* contact us */
.info_part .icon_wrapper {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    border: 1px solid #FFBD05;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* become a partner */
.bussiness_category_card .img_wrapper {
    width: 100%;
    height: 280px;
    border-radius: 15px;
    overflow: hidden;
}

.bussiness_category_card .img_wrapper a,
.bussiness_category_card .img_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}



/* //event */
.event_starting_banner {
    background-image: linear-gradient(99.85deg, #333230 0%, rgba(51, 50, 48, 0.7) 59.9%, rgba(51, 50, 48, 0) 100%), url("../images/event.png");
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
}

.featured_event .featured_event_content {
    min-height: 560px;
    max-height: 560px;
    height: 560px;
}

.featured_event .featured_event_content .featured_event_card {
    border-radius: 14px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: hidden;
}

.featured_event .featured_event_content .featured_event_card .text_content {
    padding: 60px 28px 28px 28px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 23.96%, #000000 100%);
    height: auto !important;
}

.featured_event .featured_event_content .featured_event_card.sm .text_content {
    padding: 36px 18px 18px 18px;
}

.featured_event .featured_event_content .featured_event_card .event_date {
    padding: 6px 18px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.4s ease;
}

.featured_event .featured_event_content .featured_event_card .event_date:hover {
    background-color: #FFBD05;
}

.featured_event .featured_event_content .right_side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
}

.featured_event .featured_event_content .right_side .featured_event_card {
    height: calc(50% - 12px);
    width: 100%;
}

.featured_event .featured_event_content .featured_event_card .event_cost {
    padding: 10px 24px;
    background-color: #27AE60;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.featured_event .featured_event_content .featured_event_card .event_cost.sm {
    padding: 6px 18px;
    font-size: 18px;
}

.featured_event .featured_event_content .featured_event_card .event_cost.sm span {
    font-size: 14px;
}

.featured_event .featured_event_content .featured_event_card .event_cost span {
    font-size: 18px;
    font-weight: 400;
}

.featured_event .featured_event_content .featured_event_card .text_content {
    padding: 36px 18px 18px 18px;
}

.featured_event .featured_event_content .featured_event_card.bg-fit {
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
}

.error_wrapper {
    margin-top: 0px !important;
    padding-top: 0px !important;
    width: 100%;
    height: 100vh;
}

.error_wrapper .text_content {
    position: relative;
    z-index: 1;
}

.error_wrapper .text_content p {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    text-align: center;
    text-transform: capitalize;
}

.error_wrapper .text_content h1 {
    font-size: 180px;
    font-weight: 700;
    color: #FFBD05 !important;
    line-height: 0.9;
    text-align: center;
}

.error_wrapper .text_content h1 .numaric_2 {
    color: #FFBD05 !important;
    /* margin-left: -48px; */
}

.pre-loader {
    background-color: #4e462ecc;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    opacity: 1;
    transition: all 0.7s ease;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pre-loader .ant-spin .ant-spin-dot .ant-spin-dot-item {
    background-color: #ffffff;
}

.pre-loader.loaded {
    opacity: 0;
    visibility: hidden;
}

.your-favorite-food ul.steps_lists {
    margin-bottom: 24px;
}

.your-favorite-food ul.steps_lists li {
    color: #787878;
    font-size: 18px;
    font-weight: 400;
    list-style: disc;
    position: relative;
    padding-left: 18px;
}

.your-favorite-food ul.steps_lists li:not(:last-child) {
    margin-bottom: 6px;
}

.your-favorite-food ul.steps_lists li::before{
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #222;
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      left: 0px;
}
.order_ctg_filter_wrapper a .ctg_icon{
    overflow: hidden;
}
.order_ctg_filter_wrapper a .ctg_icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.foods_menu_categories .custom_category_navigation{
     width: 100%;
     position: relative;
}
.foods_menu_categories  .nav_button{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
}
.foods_menu_categories .nav_button.prev{
      top: 46%;
      transform: translateY(-50%);
      left: 0px;
}
.foods_menu_categories  .nav_button.next{
    top: 46%;
    transform: translateY(-50%);
    right: 0px;
}